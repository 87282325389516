import { ComponentPropsWithoutRef } from 'react'

const IconImage = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg viewBox="0 0 25 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.25 7.5C13.4551 7.5 12 8.95507 12 10.75C12 12.5449 13.4551 14 15.25 14C17.0449 14 18.5 12.5449 18.5 10.75C18.5 8.95507 17.0449 7.5 15.25 7.5ZM13.5 10.75C13.5 9.7835 14.2835 9 15.25 9C16.2165 9 17 9.7835 17 10.75C17 11.7165 16.2165 12.5 15.25 12.5C14.2835 12.5 13.5 11.7165 13.5 10.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 4.25H2.25V19.75H21.75V4.25ZM3.75 13.4395V5.75H20.25V18.25H14.375L8.13572 9.93096L3.75 13.4395ZM7.86428 12.069L12.5 18.25H3.75V15.3605L7.86428 12.069Z"
      fill="currentColor"
    />
  </svg>
)

export default IconImage
