const IconCircleMinus = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Oval"
      fillOpacity="0.5"
      fill="#D8D8D8"
      cx="10"
      cy="10"
      r="10"
    ></circle>
    <path
      d="M13.7499806,9.31251118 L6.24999851,9.31251118 C5.90488367,9.31251118 5.625,9.59239485 5.625,9.93750969 L5.625,10.5625082 C5.625,10.907623 5.90488367,11.1875067 6.24999851,11.1875067 L13.7499806,11.1875067 C14.0950955,11.1875067 14.3749791,10.907623 14.3749791,10.5625082 L14.3749791,9.93750969 C14.3749791,9.59239485 14.0950955,9.31251118 13.7499806,9.31251118 Z"
      fill="#08081F"
      fillRule="nonzero"
    ></path>
  </svg>
)

export default IconCircleMinus
