import jwt_decode from 'jwt-decode'

import {
  // IAuthUserInfo, IJwtInfo,
  IJwtPayload
} from 'types/auth'
import { UserRole } from 'constants/auth'

import { IParseUserUtil } from './type'

export const parseUser: IParseUserUtil = (jwt: string) => {
  jwt_decode<IJwtPayload>(jwt)
  const {
    userId,
    role,
    email,
    userName = null,
    fullName = null,
    ...jwtInfo
  } = jwt_decode<IJwtPayload>(jwt)

  return {
    user: {
      userId,
      role: UserRole[role],
      email,
      userName,
      fullName,
      organisationId: jwtInfo?.organisationId ?? null
    },
    jwtInfo,
    organisationId: jwtInfo?.organisationId ?? null
  }
}
