const IconPrint = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" width="22px" height="22px" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2H18V7H22V19H18V22H6V19H2V7H6V2ZM6 17V13H18V17H20V9H4V17H6ZM16 4V7H8V4H16ZM8 20V15H16V20H8Z"
      fill="currentColor"
    />
  </svg>
)

export default IconPrint
