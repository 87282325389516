const IconEditSquare = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0059 5.5L19 8.49407L10.9941 16.5L8.00281 16.4972L8 13.5059L16.0059 5.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M16.0059 5.5L19 8.49407L10.9941 16.5L8.00281 16.4972L8 13.5059L16.0059 5.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M16 10.5L14 8.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M19 12.919V19.5H5V5.5H11.4738"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
)

export default IconEditSquare
