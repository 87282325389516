const IconDotsAltR = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.75 13L6.75 10.5L4.25 10.5L4.25 13L6.75 13Z"
      fill="currentColor"
    />
    <path d="M13.25 10.5V13L10.75 13V10.5L13.25 10.5Z" fill="currentColor" />
    <path d="M19.75 10.5V13L17.25 13V10.5L19.75 10.5Z" fill="currentColor" />
  </svg>
)

export default IconDotsAltR
