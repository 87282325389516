const IconHome = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.85599L22 7.41154V21.9999H14V14.9999H10V21.9999H2V7.41154L12 1.85599ZM4 8.58835V19.9999H8V12.9999H16V19.9999H20V8.58835L12 4.14391L4 8.58835Z"
      fill="currentColor"
    />
  </svg>
)

export default IconHome
