const IconTwitter = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path
        fill="currentColor"
        d="M9.9,20.4c-4.6-0.1-6.1-0.2-7.1-0.4c-0.6-0.1-1.2-0.4-1.6-0.8c-0.3-0.3-0.6-0.8-0.8-1.5c-0.2-0.6-0.2-1-0.3-2.2
	c-0.1-2.6-0.2-4.7,0-7.1c0.1-1.3,0.2-2.9,1.2-3.8c0.5-0.4,1-0.7,1.6-0.8C3.9,3.7,7.9,3.6,12,3.6c4.1,0,8.2,0.1,9.1,0.3
	C21.9,4,22.6,4.5,23,5c0.9,1.4,0.9,3.1,1,4.5c0,0.6,0,4.3,0,5c-0.1,2.2-0.3,2.9-0.6,3.7c-0.2,0.5-0.4,0.8-0.7,1.1
	c-0.5,0.4-1,0.7-1.7,0.9C17.1,20.4,13.8,20.5,9.9,20.4z M16,11.8c-2.2-1.2-4.3-2.3-6.5-3.4v6.8C11.8,13.9,14.2,12.8,16,11.8
	C16,11.7,16,11.8,16,11.8z"
      />
    </g>
  </svg>
)

export default IconTwitter
