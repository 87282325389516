import { createContext, useContext } from 'react'
import { User } from 'firebase/auth'

export type AuthStatus = 'authenticated' | 'unauthenticated' | 'loading'

export interface IFirebaseAuthContext {
  status: AuthStatus
  user: User | null
  signIn: () => void
  signOut: () => void
}

const defaultFirebaseAuthCondext: IFirebaseAuthContext = {
  status: 'loading',
  user: null,
  signIn: () => {
    throw new Error('Function not implemented.')
  },
  signOut: () => {
    throw new Error('Function not implemented.')
  }
}

const FirebaseAuthContext = createContext(defaultFirebaseAuthCondext)

export const useFirebaseAuth = () => {
  return useContext(FirebaseAuthContext)
}

export const FirebaseAuthContextProvider = FirebaseAuthContext.Provider
