import Head from 'next/head'

import BlogPostingSchema from './schemas/BlogPostingSchema'
import BreadcrumbListSchema from './schemas/BreadcrumbListSchema'
import JobPostingSchema from './schemas/JobPostingSchema'
import {
  ISEOMarkupSchemaProps,
  MarkupSchemaMapType,
  MarkupSchemaType
} from './types'

const markupSchemaMap: MarkupSchemaMapType = {
  JobPosting: JobPostingSchema,
  BlogPosting: BlogPostingSchema,
  BreadcrumbList: BreadcrumbListSchema
}

const SEOMarkupSchema = <Type extends MarkupSchemaType>({
  type,
  data
}: ISEOMarkupSchemaProps<Type>) => {
  const schemaBuilder = markupSchemaMap[type]
  return (
    <Head>
      <script
        key={type}
        id={type}
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaBuilder({ data }))
        }}
      />
    </Head>
  )
}

export default SEOMarkupSchema
