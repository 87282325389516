import { ComponentPropsWithoutRef } from 'react'

const WorkCaseIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg viewBox="0 0 25 25" {...props}>
    <path
      d="M7.25 2.25H16.75V6.25H21.75V20.75H2.25V6.25H7.25V2.25ZM8.75 6.25H15.25V3.75H8.75V6.25ZM3.75 11.935V19.25H20.25V11.935L12 13.7683L3.75 11.935ZM20.25 10.3984V7.75H3.75V10.3984L12 12.2317L20.25 10.3984Z"
      fill="currentColor"
    />
  </svg>
)

export default WorkCaseIcon
