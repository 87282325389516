import { PageAuthSettingsContextProvider } from 'context'

import { IAuthGatesProps } from './types'
import useAuthGates from './useAuthGates'

const AuthGates = ({
  children,
  isModal,
  auth: authSettings
}: IAuthGatesProps) => {
  const { isAvailable, ensureAuthSettings, needRedirect } = useAuthGates(
    authSettings,
    isModal
  )

  if (!isAvailable && needRedirect) {
    return null
  }

  return (
    <PageAuthSettingsContextProvider value={ensureAuthSettings}>
      {children}
    </PageAuthSettingsContextProvider>
  )
}

export default AuthGates
