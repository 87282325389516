import { addDays, addMonths, addWeeks } from 'date-fns'
import { TimePeriods } from 'enums'

import { IDateDuration } from 'types/time'

export const short = (timestamp: number, locale = 'en') => {
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit'
  }).format(timestamp)
}

export const shortDay = (timestamp: number, locale = 'en') => {
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short'
  }).format(timestamp)
}

export const shortDate = (date: Date, locale = 'en') => {
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).format(date.getTime())
}

export const shortArticleDate = (date: Date, locale = 'en') => {
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(date.getTime())
}

export const monthDay = (date: Date, separator = '/') => {
  const monthDayFormatter = new Intl.DateTimeFormat('en', {
    month: '2-digit',
    day: '2-digit'
  })
  const [month, day] = monthDayFormatter.format(date).split('/')

  return `${month}${separator}${day}`
}

export const year = (date: Date) => {
  return new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
    date.getTime()
  )
}

export const shortTime = (timestamp: number, locale = 'en') => {
  return new Intl.DateTimeFormat(locale, {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit'
  }).format(timestamp)
}

export const duration = (start: number, end?: number): IDateDuration => {
  const currentTime = end ?? Date.now()
  let mil = currentTime - start

  let seconds = (mil / 1000) | 0
  mil -= seconds * 1000

  let minutes = (seconds / 60) | 0
  seconds -= minutes * 60

  let hours = (minutes / 60) | 0
  minutes -= hours * 60

  let days = (hours / 24) | 0
  hours -= days * 24

  let weeks = (days / 7) | 0
  days -= weeks * 7

  const years = (weeks / 52.143) | 0
  weeks -= years * 52.143

  const isYesterday = days === 1 && weeks === 0

  return {
    years,
    weeks,
    days,
    hours,
    minutes,
    seconds,
    isYesterday
  }
}

export const getAddFuncByPeriod = (period: TimePeriods) => {
  switch (period) {
    case TimePeriods.Daily:
      return addDays
    case TimePeriods.Weekly:
      return addWeeks
    case TimePeriods.Monthly:
      return addMonths
  }
}

module.exports = {
  short,
  shortDay,
  shortDate,
  shortArticleDate,
  monthDay,
  year,
  shortTime,
  duration,
  getAddFuncByPeriod
}
