import {
  ILanguageCode,
  IOrganisation,
  IOrganisationAddress,
  IOrganisationDescription,
  IOrganisationGallery,
  IOrganisationLanguages,
  IOrganisationLogo,
  IOrganisationOverallInformation,
  IOrganisationPhoto,
  IOrganisationSocialNetworks,
  IOrganisationSubscriptionPlan
} from 'data-transfers/dto'
import {
  ICheckOrgExistRequest,
  IGetOrganisation,
  IGetOrganisations,
  IOrganisationPaymentStepRequest
} from 'data-transfers/requests'
import {
  ICheckOrganisationExistsResponse,
  IInitiatePaymentResponse,
  IPaginatedResponse
} from 'data-transfers/responses'

import { authClient, parseContentRange } from 'utils'

export const updateOrganisation = async ({
  org,
  ...params
}: { org: IOrganisation } & ILanguageCode) => {
  const response = await authClient.put<IOrganisation>(
    `/organisations/${org.id}`,
    org,
    { params }
  )
  return response.data
}

export const getOrganisation = async ({
  id,
  languageCode = 'en',
  ...params
}: IGetOrganisation) => {
  const response = await authClient.get<IOrganisation>(`/organisations/${id}`, {
    params: {
      languageCode,
      ...params
    }
  })
  return response.data
}

export const getOrganisations = async ({
  languageCode = 'en',
  ...params
}: IGetOrganisations): Promise<
  IPaginatedResponse<{ organisations: IOrganisation[] }>
> => {
  const { data, headers } = await authClient.get<IOrganisation[]>(
    `/organisations`,
    {
      params: {
        languageCode,
        ...params
      }
    }
  )
  const pagination = parseContentRange(headers)

  return { organisations: data, pagination }
}

export const checkIsOrganisationExistsByCompanyCode = async ({
  registrationNumber,
  countryCode
}: ICheckOrgExistRequest) => {
  const { data } = await authClient.get<ICheckOrganisationExistsResponse>(
    `/organisations/check-if-exists/${registrationNumber}`,
    {
      params: {
        countryCode
      }
    }
  )
  return data
}

export const patchOrganisationOverallInformation = async ({
  data
}: {
  data: IOrganisationOverallInformation
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/overall-information',
    data
  )
  return response.data
}

export const patchOrganisationAddress = async ({
  data
}: {
  data: IOrganisationAddress
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/address',
    data
  )
  return response.data
}

export const patchOrganisationShowInformation = async () => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/show-information'
  )
  return response.data
}

export const patchOrganisationLanguages = async ({
  data
}: {
  data: IOrganisationLanguages
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/languages',
    data
  )
  return response.data
}

export const patchOrganisationDescription = async ({
  data
}: {
  data: IOrganisationDescription
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/description',
    data
  )
  return response.data
}

export const patchOrganisationSocialNetworks = async ({
  data
}: {
  data: IOrganisationSocialNetworks
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/social-networks',
    data
  )
  return response.data
}

export const patchOrganisationLogo = async ({
  data
}: {
  data: IOrganisationLogo
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/logo',
    data
  )
  return response.data
}

export const patchOrganisationPhoto = async ({
  data
}: {
  data: IOrganisationPhoto
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/photo',
    data
  )
  return response.data
}

export const patchOrganisationGallery = async ({
  data
}: {
  data: IOrganisationGallery
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/gallery',
    data
  )
  return response.data
}

export const patchOrganisationSubscription = async ({
  data
}: {
  data: IOrganisationSubscriptionPlan
}) => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/subscription',
    data
  )
  return response.data
}

export const patchOrganisationMakePayment = async (
  data: IOrganisationPaymentStepRequest
) => {
  const response = await authClient.patch<IInitiatePaymentResponse>(
    '/organisations/payment',
    data
  )
  return response.data
}

export const patchOrganisationPolicy = async () => {
  const response = await authClient.patch<IOrganisation>(
    '/organisations/privacy-policy'
  )
  return response.data
}
