const IconLinkedin = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" width="22px" height="22px" {...props}>
    <path
      d="M11.915 0C5.335 0 0 5.336 0 11.915c0 6.58 5.336 11.915 11.915 11.915 6.58 0 11.915-5.336 11.915-11.915C23.83 5.335 18.494 0 11.915 0zM8.453 18.012H5.55v-8.73h2.902v8.73zM7.002 8.09h-.02c-.973 0-1.603-.67-1.603-1.508 0-.857.65-1.509 1.642-1.509.993 0 1.604.652 1.623 1.509 0 .837-.63 1.508-1.642 1.508zm11.914 9.922h-2.901v-4.67c0-1.174-.42-1.975-1.47-1.975-.802 0-1.28.54-1.49 1.062-.076.186-.095.447-.095.708v4.875H10.06s.038-7.911 0-8.73h2.901v1.236c.386-.595 1.076-1.441 2.615-1.441 1.91 0 3.341 1.248 3.341 3.93v5.005z"
      fill="currentColor"
    />
  </svg>
)

export default IconLinkedin
