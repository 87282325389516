import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

import { IAuthInitialProps } from 'types/app'
import { isSSR } from 'utils'
import { isRouteAvailable } from 'utils/auth'

const useAuthGates = (authSettings?: IAuthInitialProps, isModal?: boolean) => {
  const session = useSession()

  const router = useRouter()

  const result = isRouteAvailable(session, authSettings)

  const needRedirect =
    (!isModal && session.status === 'unauthenticated') ||
    session.status === 'authenticated'

  // Redirect only when auth is loaded
  // Blank screen will be displayed before this
  // Redirect should not happen on server side since `useRouter` won't work there
  // Server side redirects are handled inside `withPageSettings` util
  if (result.redirectUrl !== null && !isSSR() && needRedirect) {
    // Perform redirect on a same render cycle to prevent UI flickering

    router.replace(result.redirectUrl)
  }

  return { ...result, needRedirect }
}

export default useAuthGates
