const IconInstagram = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path
        d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M19,10.3c-1,0-1.9-0.2-2.8-0.6
	c-0.4-0.2-0.7-0.4-1.1-0.6c0,1.9,0,3.9,0,5.8c-0.1,0.9-0.4,1.9-0.9,2.6c-0.9,1.3-2.4,2.1-3.9,2.1c-1,0.1-1.9-0.2-2.7-0.7
	c-1.3-0.8-2.3-2.2-2.4-3.8c0-0.3,0-0.7,0-1c0.1-1.3,0.7-2.5,1.7-3.3C8,9.8,9.5,9.4,11,9.7c0,1,0,2,0,3c-0.7-0.2-1.4-0.2-2,0.2
	C8.5,13.1,8.2,13.6,8,14c-0.1,0.3-0.1,0.7-0.1,1.1c0.2,1.1,1.2,2,2.3,1.9c0.7,0,1.5-0.4,1.8-1.1c0.1-0.2,0.3-0.4,0.3-0.7
	c0.1-1.2,0-2.4,0-3.6c0-2.7,0-5.4,0-8c0.9,0,1.7,0,2.6,0c0.1,1,0.4,2.1,1.2,2.8C16.9,7.1,18,7.5,19,7.6V10.3z"
      />
    </g>
  </svg>
)

export default IconInstagram
