import { GetServerSidePropsContext, NextApiResponse } from 'next'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { ParsedUrlQuery } from 'querystring'

import { ICookieOptions, ISaveJWTToCookies } from './types'

const EXPIRATION_ACCESS_TOKEN = 'expiration-access-token'
const ACCESS_TOKEN = 'access-token'
const REFRESH_TOKEN = 'refresh-token'
const REMEMBER_ME = 'remember-me'
const COOKIE_PATH = '/'
export const saveJwtInfo = (
  res: NextApiResponse,
  { jwt, expiration, remember, refreshToken }: Partial<ISaveJWTToCookies>
) => {
  const cookieOptions: ICookieOptions = { path: COOKIE_PATH }
  if (expiration) {
    const expiresDate = new Date(expiration)
    const dateNow = new Date()

    cookieOptions.maxAge = remember
      ? (expiresDate.getTime() - dateNow.getTime()) / 1000
      : undefined
    setCookie({ res }, EXPIRATION_ACCESS_TOKEN, expiration, cookieOptions)
  }

  jwt && setCookie({ res }, ACCESS_TOKEN, jwt, cookieOptions)
  refreshToken && setCookie({ res }, REFRESH_TOKEN, refreshToken, cookieOptions)
  remember &&
    setCookie({ res }, REMEMBER_ME, remember.toString(), cookieOptions)
}

export const getJwtInfo = (cookies?: NextApiRequestCookies) => {
  const localCookies = cookies ?? parseCookies()
  const jwt = localCookies[ACCESS_TOKEN]
  const refreshToken = localCookies[REFRESH_TOKEN]
  const expires = localCookies[EXPIRATION_ACCESS_TOKEN]
  const remember = localCookies[REMEMBER_ME]

  return { jwt, expires, remember, refreshToken }
}

export const clearJwtInfo = <Q extends ParsedUrlQuery>(
  context: GetServerSidePropsContext<Q> | null = null
) => {
  const cookieOptions: ICookieOptions = { path: COOKIE_PATH }
  destroyCookie(context || {}, ACCESS_TOKEN, cookieOptions)
  destroyCookie(context || {}, EXPIRATION_ACCESS_TOKEN, cookieOptions)
  destroyCookie(context || {}, REMEMBER_ME, cookieOptions)
  destroyCookie(context || {}, REFRESH_TOKEN, cookieOptions)
}
