import { ComponentPropsWithoutRef } from 'react'

const IconRefresh = ({
  viewBox = '0 0 24 24',
  width = 24,
  height = 24,
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    {...props}
  >
    <path
      d="M20.25 12C20.25 7.44365 16.5564 3.75 12 3.75V2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61525 21.75 2.25002 17.3848 2.25002 12C2.25002 9.20732 3.42448 6.68932 5.30459 4.9125L1.77502 4.9125L1.77502 3.4125L8.27502 3.4125V9.9125H6.77502L6.77502 5.61509C4.92735 7.12888 3.75002 9.42717 3.75002 12C3.75002 16.5563 7.44368 20.25 12 20.25C16.5564 20.25 20.25 16.5563 20.25 12Z"
      fill="currentColor"
    />
  </svg>
)

export default IconRefresh
