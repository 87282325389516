import { SkeletonCard, SkeletonDescription } from '../components'

import { IDefaultSkeleton } from './types'

const DefaultSkeleton = ({ title }: IDefaultSkeleton) => {
  return (
    <section className="mb-10 w-full">
      <div className="">
        <div className="flex items-center justify-between border-b-2 border-black">
          <h2 className="pb-2 text-2xl font-bold">
            {title ?? 'Title'}
            <i className="dot" />
          </h2>
        </div>
        <SkeletonDescription />
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-5 py-5 md:grid-cols-2 lg:grid-cols-3">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>
    </section>
  )
}

export default DefaultSkeleton
