const IconReply = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4377 4.6636L2.1991 11.4355L11.4377 19.4453V15.1273H13.4939C14.8272 15.1273 16.115 15.6116 17.1179 16.4901L20.5002 19.4527V15.3728C20.5002 11.7829 17.59 8.87277 14.0002 8.87277H11.4377V4.6636ZM10.4377 6.63648V9.87277H14.0002C17.0377 9.87277 19.5002 12.3352 19.5002 15.3728V17.2474L17.7768 15.7378C16.5916 14.6996 15.0696 14.1273 13.4939 14.1273H10.4377V17.2548L3.80121 11.501L10.4377 6.63648Z"
    />
  </svg>
)

export default IconReply
