import { useQueries } from '@tanstack/react-query'
import { RepresentationFor } from 'enums'

import { messageKeys } from './keys'
import { getUserRepresentation } from './message.service'

const useRepresentationForEmployer = ({ userIds }: { userIds: string[] }) => {
  return useQueries({
    queries: userIds.map((userId) => {
      return {
        queryKey: messageKeys.userRepresentation(userId),
        queryFn: async () => {
          const data = await getUserRepresentation(
            {
              userId
            },
            RepresentationFor.Employer
          )
          return data
        },
        staleTime: 1000 * 60 * 60, //one Hour
        cacheTime: 1000 * 60 * 60 * 2
      }
    })
  })
}

export default useRepresentationForEmployer
