import { useQuery } from '@tanstack/react-query'
import { IShortUserInfo } from 'data-transfers/dto'
import { RepresentationFor } from 'enums'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { messageKeys } from './keys'
import { getUsersRepresentation } from './message.service'

const useRepresentationForCandidates = ({
  payload,
  options
}: IUseQueryHookWithPayload<{ userIds: string[] }, IShortUserInfo[]>) => {
  return useQuery({
    queryKey: messageKeys.userRepresentations(payload.userIds),
    queryFn: async () => {
      const data = await getUsersRepresentation(
        payload,
        RepresentationFor.Candidate
      )
      return data
    },
    ...options
  })
}

export default useRepresentationForCandidates
