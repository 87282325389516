const IconClipboard = (props: { className?: string }) => (
  <svg viewBox="0 0 22 22" width="22px" height="22px" {...props}>
    <path
      d="M19.25 5.5v14.667H6.417V5.5H19.25zm-1.833 1.833H8.25v11h9.167v-11zm-3.667-5.5v1.834H4.583v11.926H2.75V1.833h11z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default IconClipboard
