import { destroyCookie, parseCookies, setCookie } from 'nookies'

import { CookieSerializeOptions } from './types'
const COOKIE_PATH = '/'

export const setFromClient = (
  name: string,
  value: string,
  options?: CookieSerializeOptions
) => {
  setCookie(null, name, value, {
    maxAge: 24 * 60 * 60,
    path: COOKIE_PATH,
    ...options
  })
}

export const getFromClient = (name: string) => {
  const cookies = parseCookies()

  return cookies?.[name] ?? null
}

export const removeFromClient = (name: string) => {
  destroyCookie(null, name)
}
