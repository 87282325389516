import Link from 'next/link'
import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { SizeVariant } from 'enums'

import Spinner from 'components/Spinner'

import { TextTooltip } from '../Tooltip'

import { IButtonProps } from './types'

const sizeVariant: Record<SizeVariant, string> = {
  [SizeVariant.Small]: 'p-1 px-3 text-base',
  [SizeVariant.Medium]: 'p-1 px-4 tracking-wide text-lg',
  [SizeVariant.Large]: 'p-2 px-6 tracking-wide'
}

const needActionClasses =
  'relative after:shadow-red-550 after:shadow-round after:animate-pulse after:w-full after:h-full after:absolute after:left-0 '

const Button = ({
  href,
  onClick,
  className,
  type = 'button',
  variant = 'primary',
  isSpinner,
  isLoading,
  disabled,
  button,
  size = SizeVariant.Large,
  children,
  tooltipContent,
  tooltipContentClassName,
  needAction
}: PropsWithChildren<IButtonProps>) => {
  const cN =
    href && !button
      ? clsx(
          'flex cursor-pointer items-center font-semibold justify-between  uppercase text-black outline-none  transition-all hover:text-red-550 active:text-red-600',
          sizeVariant[size],
          needAction && needActionClasses,
          className
        )
      : clsx(
          'block flex cursor-pointer items-center justify-between font-semibold uppercase transition-all disabled:cursor-not-allowed disabled:text-gray-400',
          sizeVariant[size],
          variant === 'primary' &&
            'border-[2px] border-black  text-black hover:border-red-550 hover:bg-red-550 hover:text-white active:border-red-550 active:bg-red-550 active:text-white disabled:border-gray-400 disabled:bg-white',
          variant === 'secondary' &&
            'text-black hover:text-red-550 active:text-red-600',
          variant == 'accent' &&
            'border-[2px] border-transparent px-6 text-white bg-red-550 hover:bg-white hover:border-black hover:text-black active:bg-white active:text-black disabled:bg-grey',
          needAction && needActionClasses,
          className
        )
  if (href) {
    return (
      <Link href={href} onClick={onClick} className={!button ? cN : ''}>
        {button ? <button className={cN}>{children}</button> : children}
      </Link>
    )
  }
  const ButtonContent = (
    <button
      className={cN}
      type={type}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      {isSpinner && (
        <Spinner
          isSM
          size={18}
          isVisible={isLoading}
          className="fade-in mr-1"
        />
      )}
      {children}
      {isSpinner && <div className="w-[calc(18px_+_0.25rem)]"></div>}
    </button>
  )
  return tooltipContent ? (
    <TextTooltip
      tooltipContent={tooltipContent}
      tooltipContentClassName={tooltipContentClassName}
    >
      {ButtonContent}
    </TextTooltip>
  ) : (
    ButtonContent
  )
}

export default Button
