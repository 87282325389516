const IconAdjust = (props: { className?: string }) => (
  <svg viewBox="0 0 22 22" style={{ width: '22px', height: '22px' }} {...props}>
    <path
      fill="currentColor"
      d="M7.352 1.414v16.161l2.937-2.937 1.414 1.414-3.943 3.944.006.006-1.414 1.414L1 16.064l1.414-1.414 2.938 2.938V1.414h2zM15.477 1l1.414 1.414-.006.006 3.943 3.944-1.414 1.414-2.937-2.937V21h-2V4.829l-2.938 2.938-1.414-1.414L15.477 1z"
    />
  </svg>
)

export default IconAdjust
