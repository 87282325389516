import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { Session } from 'next-auth'

import { getSession } from './auth'
import { setSSRJwt } from './client'

export const isSSR = () => typeof window === 'undefined'

export const doSSRRequest = async <T>(
  coockies: NextApiRequestCookies,
  func: (session: Session) => Promise<T>
) => {
  const session = await getSession(coockies)
  if (!session) return null

  setSSRJwt(coockies)

  return func(session)
}
