import { IUserSettings } from 'data-transfers/dto'
import { INotificationDto } from 'data-transfers/dto/notification'
import { IChangeEmail } from 'data-transfers/requests'

import IVerifyChangeEmail from 'validators/changeEmailValidator'
import { authClient } from 'utils'

import { IChangePassword } from './types'

export const changePassword = async (changePassword: IChangePassword) => {
  const response = await authClient.post(
    `/auth/change-password`,
    changePassword
  )
  return response.data
}

export const verifyChangeEmail = async (
  verifyChangeEmail: IVerifyChangeEmail
) => {
  const response = await authClient.post(
    `/user-profile/email`,
    verifyChangeEmail
  )
  return response.data
}
export const changeEmail = async (changeEmail: IChangeEmail) => {
  const response = await authClient.patch(`/user-profile/email`, changeEmail)
  return response.data
}

export const saveUserSettings = async (settings: IUserSettings) => {
  const response = await authClient.post<IUserSettings>(
    `/user-profile/settings`,
    settings
  )
  return response.data
}
export const getUserSettings = async () => {
  const response = await authClient.get<IUserSettings>(`/user-profile/settings`)
  return response.data
}

export const notificationWasShown = async (payload: INotificationDto) => {
  const { data } = await authClient.patch<INotificationDto>(
    `/user-profile/notifications/${payload.notificationId}`
  )
  return data
}

export const getNotificationToShown = async () => {
  const { data } = await authClient.get<INotificationDto | null>(
    `/user-profile/notifications`
  )
  return data
}
