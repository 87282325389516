import type { BuildQueryURLArgs, Client, ClientConfig } from '@prismicio/client'
import type { CreateClientConfig } from '@prismicio/next'

import type { AllDocumentTypes } from 'types/prismic'
import { createClient } from 'env/prismicio'

import { getPrismicLangCode } from './prismic.utils'

const getPageParts = async (
  client: Client<AllDocumentTypes>,
  params?: Partial<BuildQueryURLArgs>
) => {
  const navigationPromise = client.getSingle('navigation', params)
  const footerPromise = client.getSingle('navigationfooter', params)
  const settingsPromise = client.getSingle('settings', params)

  return { navigationPromise, footerPromise, settingsPromise }
}
export const getFullPageByUID = async <T extends AllDocumentTypes['type']>(
  documentType: T,
  uid: string,
  params: Partial<BuildQueryURLArgs & { locale: string }> = {},
  clientConfig?: ClientConfig & CreateClientConfig
) => {
  try {
    const { locale, lang, ...rest } = params
    const client = createClient(clientConfig)

    const pagePromise = client.getByUID(documentType, uid, {
      ...rest,
      lang: lang || getPrismicLangCode(locale)
    })

    const { navigationPromise, footerPromise, settingsPromise } =
      await getPageParts(client, {
        ...rest,
        lang: lang || getPrismicLangCode(locale)
      })

    const [page, navigation, navigationFooter, settings] = await Promise.all([
      pagePromise,
      navigationPromise,
      footerPromise,
      settingsPromise
    ])

    return { page, navigation, navigationFooter, settings }
  } catch {
    console.error(
      `Cannot load page parts from prismic for type: ${documentType} with uid: ${uid}`
    )
    return {
      page: null,
      navigation: null,
      navigationFooter: null,
      settings: null
    }
  }
}

export const getFullSinglePage = async <T extends AllDocumentTypes['type']>(
  documentType: T,
  params: Partial<BuildQueryURLArgs & { locale: string }> = {},
  clientConfig?: ClientConfig & CreateClientConfig
) => {
  try {
    const { locale, lang, ...rest } = params
    const client = createClient(clientConfig)

    const pagePromise = await client.getSingle(documentType, {
      ...rest,
      lang: lang || getPrismicLangCode(locale)
    })

    const { navigationPromise, footerPromise, settingsPromise } =
      await getPageParts(client, {
        ...rest,
        lang: lang || getPrismicLangCode(locale)
      })

    const [page, navigation, navigationFooter, settings] = await Promise.all([
      pagePromise,
      navigationPromise,
      footerPromise,
      settingsPromise
    ])

    return { page, navigation, navigationFooter, settings }
  } catch {
    console.error(
      `Cannot load single page parts from prismic for type: ${documentType}`
    )
    return {
      page: null,
      navigation: null,
      navigationFooter: null,
      settings: null
    }
  }
}
export const getLayoutParts = async (
  params: Partial<BuildQueryURLArgs & { locale: string }> = {},
  clientConfig?: ClientConfig & CreateClientConfig
) => {
  try {
    const { locale, lang, ...rest } = params
    const client = createClient(clientConfig)

    const { navigationPromise, footerPromise, settingsPromise } =
      await getPageParts(client, {
        ...rest,
        lang: lang || getPrismicLangCode(locale)
      })

    const [navigation, navigationFooter, settings] = await Promise.all([
      navigationPromise,
      footerPromise,
      settingsPromise
    ])

    return { navigation, navigationFooter, settings }
  } catch {
    console.error('Cannot load layout parts from prismic for type')
    return {
      navigation: null,
      navigationFooter: null,
      settings: null
    }
  }
}

export const getSinglePage = async <T extends AllDocumentTypes['type']>(
  documentType: T,
  params: Partial<BuildQueryURLArgs & { locale: string }> = {},
  clientConfig?: ClientConfig & CreateClientConfig
) => {
  try {
    const { locale, lang, ...rest } = params
    const client = createClient(clientConfig)

    const result = await client.getSingle(documentType, {
      ...rest,
      lang: lang || getPrismicLangCode(locale)
    })

    return result
  } catch {
    console.error(
      `Cannot load single page parts from prismic for type: ${documentType}`
    )
    return null
  }
}
