const IconGoogle = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z
        M17.6,18.1c-1.3,1.2-3.1,1.9-5.3,1.9c-3.1,0-5.8-1.8-7.1-4.4c-0.5-1.1-0.9-2.3-0.9-3.6c0-1.3,0.3-2.5,0.9-3.6C6.5,5.8,9.2,4,12.3,4
        c2.2,0,4,0.8,5.3,2.1l-2.3,2.2c-0.6-0.5-1.6-1.2-3.1-1.2c-2.1,0-3.9,1.4-4.6,3.3c-0.2,0.5-0.3,1-0.3,1.6c0,0.5,0.1,1.1,0.3,1.6
        c0.6,1.9,2.4,3.3,4.6,3.3c1.2,0,2.1-0.3,2.8-0.8c1.1-0.8,1.5-1.9,1.6-2.6h-4.4v-3h7.5c0.1,0.5,0.2,1,0.2,1.6
        C20,14.6,19.1,16.7,17.6,18.1z"
      fill="currentColor"
    />
  </svg>
)

export default IconGoogle
