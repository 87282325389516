const IconTwitter = (props: { className?: string }) => (
  <svg width="32px" height="32px" viewBox="0 0 512 512" {...props}>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
      <path
        d="M2325 5109 c-474 -45 -925 -221 -1314 -512 -124 -92 -396 -364 -488
      -488 -696 -932 -696 -2166 0 -3098 92 -124 364 -396 488 -488 932 -696 2166
      -696 3098 0 124 92 396 364 488 488 696 932 696 2166 0 3098 -92 124 -364 396
      -488 488 -520 388 -1158 571 -1784 512z m74 -1879 c128 -170 237 -307 242
      -304 5 2 128 142 275 309 l266 305 165 0 c156 0 165 -1 152 -17 -8 -10 -170
      -196 -361 -415 l-348 -397 418 -553 c230 -304 422 -559 426 -565 6 -10 -62
      -13 -327 -13 l-334 0 -259 340 c-142 187 -261 339 -264 339 -3 0 -138 -152
      -300 -339 l-295 -339 -169 -1 -169 0 20 22 c83 91 753 862 753 867 0 3 -183
      244 -405 535 -223 291 -405 530 -405 532 0 2 154 4 343 4 l342 0 234 -310z"
        fill="currentColor"
      />
      <path
        d="M1875 3349 c-3 -3 264 -357 591 -785 l596 -779 95 -3 94 -3 -229 303
      c-126 167 -392 519 -592 783 -199 264 -364 482 -367 485 -6 7 -184 7 -188 -1z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default IconTwitter
