type linkType = 'advert' | 'adverts' | 'community'

export const getLocalisedLink = (type: linkType, locale: string) => {
  switch (type) {
    case 'advert':
      return `/${locale.startsWith('en') ? 'job-ad' : 'darbo-skelbimas'}/`
    case 'adverts':
      return `/${locale.startsWith('en') ? 'job-ads' : 'darbo-skelbimai'}/`
    case 'community':
      return `${
        locale.startsWith('en') ? 'expert-insights' : 'ekspertu-izvalgos'
      }`
  }
}
