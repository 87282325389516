import { ComponentPropsWithoutRef } from 'react'

const IconCommentFilled = ({
  viewBox = '0 0 24 24',
  width = 24,
  height = 24,
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.0752 2H18.0002H21.0002V20.9215L15.8167 17.2903H1.0752V13.992L1.0752 2ZM3.0752 15.2903V4H19.0002V17.0785L16.4476 15.2903H3.0752Z"
      fill="currentColor"
    />
    <path
      d="M6 8.00002H16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M6 11H13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

export default IconCommentFilled
