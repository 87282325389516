import { useQuery } from '@tanstack/react-query'
import { IUserSettings } from 'data-transfers/dto'

import { IUseQueryHook } from 'hooks/types'

import { userProfileKeys } from './keys'
import { getUserSettings } from './profile.service'

const useUserSettings = ({ options }: IUseQueryHook<void, IUserSettings>) => {
  return useQuery(
    userProfileKeys.settings(),
    async () => {
      const response = await getUserSettings()
      return response
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
export default useUserSettings
