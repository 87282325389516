import axios from 'axios'
import {
  EmailConfirmation,
  IAuthTokens,
  IRestrictions,
  IUserDto
} from 'data-transfers/dto'
import {
  IRefreshTokenRequest,
  ISignUpOrganisationEmployeeRequest,
  ISignUpRequest,
  IUpdateNameRequest
} from 'data-transfers/requests'

import { ForgotPassword } from 'validators/forgotPassword'
import { ResetPassword } from 'validators/resetPassword'
import { SignIn } from 'validators/signInValidator'
import { authClient, publicClient } from 'utils'
import { UserRole } from 'constants/auth'

export const postSignUp = async (signup: ISignUpRequest) => {
  await publicClient.post(
    `/auth/register-${
      !signup.type || signup.type === UserRole.Candidate
        ? 'candidate'
        : signup.type === UserRole.Organisation
          ? 'organisation'
          : 'organisation-employee'
    }`,
    signup
  )
}
export const signUpOrganisationEmployee = async (
  signup: ISignUpOrganisationEmployeeRequest
) => {
  await authClient.post(`/auth/register-organisation-employee`, signup)
}

export const postSignIn = async (signin: SignIn) => {
  const response = await publicClient.post<IAuthTokens>(`/auth/login`, signin)

  return response.data
}
export const refreshToken = async (refresh: IRefreshTokenRequest) => {
  const response = await publicClient.post<IAuthTokens>(
    `/auth/refresh`,
    refresh
  )
  return response.data
}

export const forgotPassword = async ({ email }: ForgotPassword) => {
  try {
    await publicClient.get(`/auth/reset-password/${email}`)
  } catch (err) {
    throw new Error(email)
  }
}

export const resetPassword = async (resetPassword: ResetPassword) => {
  try {
    await publicClient.post(`/auth/update-password`, resetPassword)
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 400) {
      throw new Error(err.response?.data as string)
    }
    throw err
  }
}

export const confirmEmail = async (confirmEmail: EmailConfirmation) => {
  const { data } = await publicClient.patch<IAuthTokens>(
    '/auth/confirm',
    confirmEmail
  )
  return data
}

export const resendConfirmEmail = async (email: string) => {
  const { data } = await publicClient.patch(`/auth/verify-email/${email}`)
  return data
}

export const me = async () => {
  const { data } = await authClient.get<IUserDto>('/auth/me')
  return data
}

export const updateName = async ({
  firstName,
  lastName
}: IUpdateNameRequest) => {
  await authClient.put('/user-profile/name', {
    firstName,
    lastName
  })
}

export const getRestrictions = async () => {
  try {
    const { data } = await authClient.get<IRestrictions>(`/auth/restrictions`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null
    }
    throw error
  }
}

export const logout = async () => {
  await authClient.post(`/auth/logout`)
}
