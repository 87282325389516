//0-100 - general
//101-200 - employer
//201-300 - candidate
export enum NotificationType {
  TooManyActiveJobAds = 101,
  StartSubscriptionExpiring = 102,
  SubscriptionExpiring = 103,

  AutoApply = 201,
  PhoneNowVisibleOnCV = 202,
  UserIndustriesSpecification = 204
}
