import { IMarkupSchemaDataProps } from '../types'

const BreadcrumbListSchema = ({
  data
}: IMarkupSchemaDataProps<'BreadcrumbList'>) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: data.items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      ...item
    }))
  }
}

export default BreadcrumbListSchema
