const IconCircleCheck = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="#E4002B"
  >
    <circle id="Oval" opacity="0.1" cx="10" cy="10" r="10"></circle>
    <path
      d="M8.39644576,13.8321156 C8.5916815,14.0273895 8.90826247,14.0273895 9.10353636,13.8321346 L14.8535303,8.08212165 C15.0487851,7.88684777 15.0487851,7.57026679 14.8535303,7.37501198 L14.1464397,6.66790231 C13.9511849,6.47264749 13.6345848,6.47264749 13.43933,6.66790231 L8.74999106,11.3572413 L6.56064615,9.16789635 C6.36539134,8.97262246 6.04879129,8.97262246 5.85353648,9.16789635 L5.14644588,9.87500602 C4.95119106,10.0702799 4.95119106,10.3868609 5.14644588,10.5821157 L8.39644576,13.8321156 Z"
      fillRule="nonzero"
    ></path>
  </svg>
)

export default IconCircleCheck
