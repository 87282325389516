export const ytLinkRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/

export const getYoutubeCode = (src: string | null) => {
  if (!src) return undefined
  const match = String(src).match(ytLinkRegex)
  if (!match) return undefined
  return match[6] || undefined
}
export const getYoutubeVideoImage = (src: string) => {
  const videoCode = getYoutubeCode(src)
  return `https://img.youtube.com/vi/${videoCode}/mqdefault.jpg`
}
