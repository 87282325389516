import { AxiosResponseHeaders } from 'axios'
import { IPagination } from 'data-transfers/responses'
import { TrackingType } from 'enums'

export const getTrackingHeader = (trackingType: TrackingType | null) => {
  if (trackingType === null) {
    return
  }
  return { 'Tracking-Type': trackingType }
}

export const parseContentRange = (
  headers: AxiosResponseHeaders
): IPagination | undefined => {
  const contentRange = headers['content-range']
  if (!contentRange?.length) {
    return
  }
  const [unit, sizes] = contentRange.split(' ')
  if (!unit || !sizes) {
    return
  }
  const [parsedRange, parsedTotal] = sizes.split('/')
  if (!parsedRange || !parsedTotal) {
    return
  }

  let range: IPagination['range'] = undefined
  if (parsedRange !== '*') {
    const [start, end] = parsedRange.split('-')
    if (start && end) {
      range = { start: Number(start), end: Number(end) }
    }
  }
  let total: IPagination['total'] = undefined
  if (parsedTotal !== '*') {
    total = Number(parsedTotal)
  }
  return { unit, range, total }
}
