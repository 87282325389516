import { useSession } from 'next-auth/react'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import chatService from 'services/chat.service'

import { useFirebaseToken } from 'hooks/useMessage'
import { AuthStatus } from 'context/firebaseAuth'

import { firebaseAuth } from 'utils/firebase'
const useFirebaseAuth = () => {
  const { status: sessionStatus } = useSession()
  const [status, setStatus] = useState<AuthStatus>('loading')
  const [isSync, setIsSync] = useState(true)

  const signIn = useCallback(async (token: string) => {
    try {
      setStatus('loading')
      await signInWithCustomToken(firebaseAuth, token)
      setStatus('authenticated')
    } catch (err) {
      setStatus('unauthenticated')
      if (axios.isAxiosError(err)) console.error({ message: err.message })
    } finally {
      setIsSync(true)
    }
  }, [])

  const signOut = useCallback(async () => {
    setIsSync(false)
    if (!firebaseAuth.currentUser?.uid) {
      return
    }

    await chatService.setUserOffline(firebaseAuth.currentUser.uid)
    await firebaseAuth.signOut()
  }, [])

  const { refetch } = useFirebaseToken({
    options: {
      refetchOnWindowFocus: false,
      enabled:
        status === 'unauthenticated' &&
        sessionStatus === 'authenticated' &&
        isSync,
      onSuccess: async ({ token }) => {
        signIn(token)
      },
      onError: () => {
        signOut()
      }
    }
  })

  useEffect(
    () =>
      onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          setStatus('authenticated')
          return
        }

        setStatus('unauthenticated')
      }),
    []
  )

  return { status, user: firebaseAuth.currentUser, signIn: refetch, signOut }
}
export default useFirebaseAuth
