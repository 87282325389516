const IconLocation = (props: { className?: string }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.5C10.067 6.5 8.5 8.067 8.5 10C8.5 11.933 10.067 13.5 12 13.5C13.933 13.5 15.5 11.933 15.5 10C15.5 8.067 13.933 6.5 12 6.5ZM10.5 10C10.5 9.17157 11.1716 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.1716 11.5 10.5 10.8284 10.5 10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C7.58172 2 4 5.58172 4 10C4 11.4721 4.51106 12.9482 5.206 14.2884C5.90543 15.6374 6.8264 16.9158 7.72807 18.0107C8.63218 19.1086 9.5335 20.0418 10.2077 20.6999C10.5454 21.0296 10.8277 21.2918 11.0269 21.4727C11.1266 21.5632 11.2055 21.6334 11.2604 21.6818C11.2878 21.7059 11.3092 21.7246 11.3241 21.7376C11.3288 21.7416 11.3328 21.7451 11.3362 21.7481L11.3417 21.7528L11.3488 21.7589C11.349 21.7591 11.3492 21.7593 12 21L11.3492 21.7593L12 22.3171L12.6508 21.7593L12 21C12.6508 21.7593 12.651 21.7591 12.6512 21.7589L12.6583 21.7528L12.6648 21.7472C12.6679 21.7445 12.6717 21.7412 12.6759 21.7376C12.6908 21.7246 12.7122 21.7059 12.7396 21.6818C12.7945 21.6334 12.8734 21.5632 12.9731 21.4727C13.1723 21.2918 13.4546 21.0296 13.7923 20.6999C14.4665 20.0418 15.3678 19.1086 16.2719 18.0107C17.1736 16.9158 18.0946 15.6374 18.794 14.2884C19.4889 12.9482 20 11.4721 20 10C20 5.58172 16.4183 2 12 2ZM12.3952 19.2688C12.2517 19.4089 12.1191 19.5357 12 19.6481C11.8809 19.5357 11.7483 19.4089 11.6048 19.2688C10.9665 18.6457 10.1178 17.7664 9.27193 16.7393C8.4236 15.7092 7.59457 14.5501 6.9815 13.3678C6.36394 12.1768 6 11.0279 6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10C18 11.0279 17.6361 12.1768 17.0185 13.3678C16.4054 14.5501 15.5764 15.7092 14.7281 16.7393C13.8822 17.7664 13.0335 18.6457 12.3952 19.2688Z"
      fill="currentColor"
    />
  </svg>
)

export default IconLocation
