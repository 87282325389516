const IconClose = ({
  width = '22px',
  height = '22px',
  viewBox = '0 0 22 22',
  ...props
}: {
  className?: string
  width?: string
  height?: string
  viewBox?: string
}) => (
  <svg width={width} height={height} viewBox={viewBox} {...props}>
    <path
      d="M10.778 9.364 4.414 3 3 4.414l6.364 6.364L3 17.142l1.414 1.414 6.364-6.364 6.364 6.364 1.414-1.414-6.364-6.364 6.364-6.364L17.142 3z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
)

export default IconClose
