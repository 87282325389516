const IconMap = (props: { className?: string }) => (
  <svg viewBox="0 0 24 22">
    <g
      transform="translate(0.000000,22.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M73 198 c-27 -30 -32 -48 -22 -75 6 -17 5 -23 -6 -23 -14 0 -18 -10 -30 -68 l-7 -32 112 0 112 0 -7 33 c-12 57 -16 67 -30 67 -11 0 -12 6 -6 23 4 12 6 29 3 37 -9 30 -46 60 -72 60 -15 0 -36 -10 -47 -22z m78 -8 c28 -15 25 -68 -6 -105 l-25 -30 -25 30 c-54 65 -13 142 56 105z m-56 -137 l25 -28 25 28 c29 33 50 30 57 -7 l5 -26 -88 0 c-74 0 -88 2 -84 15 3 8 7 22 10 30 7 22 23 18 50 -12z" />
      <path d="M89 164 c-17 -21 0 -49 31 -49 31 0 48 28 31 49 -8 9 -21 16 -31 16 -10 0 -23 -7 -31 -16z m46 -15 c10 -15 -1 -23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z" />
    </g>
  </svg>
)

export default IconMap
