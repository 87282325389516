import { ComponentPropsWithoutRef } from 'react'

const IconTrash = ({
  width = 24,
  height = 24,
  ...rest
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
    <path d="M14.75 10.25V17.75H13.25V10.25H14.75Z" fill="currentColor" />
    <path d="M10.75 17.75V10.25H9.25L9.25 17.75H10.75Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.75 2.25H7.25V6.25H3V7.75H5.25V21.75H18.75V7.75H21V6.25H16.75V2.25ZM15.25 6.25V3.75H8.75V6.25H15.25ZM6.75 7.75V20.25H17.25V7.75H6.75Z"
      fill="currentColor"
    />
  </svg>
)

export default IconTrash
