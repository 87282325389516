export enum LanguageLevel {
  NotSelected = '',
  Native = 'N',
  Beginner = 'A1',
  Novice = 'A2',
  Independent = 'B1',
  Intermediate = 'B2',
  Advanced = 'C1',
  Proficient = 'C2'
}
