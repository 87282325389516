interface IFormatMoney extends Intl.NumberFormatOptions {
  amount?: number | null
  locale?: string
}
export const formatMoney = ({
  amount,
  locale = 'en',
  currency = 'EUR',
  ...rest
}: IFormatMoney) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...rest
  })
  if (amount == null) {
    amount = 0
  }
  return formatter.format(amount)
}

export const formatMoneyWithoutSign = ({
  amount,
  locale = 'en',
  currency = 'EUR',
  ...rest
}: IFormatMoney) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    ...rest
  })
  if (amount == null) {
    amount = 0
  }
  return formatter.format(amount).replace(currency, '').trim()
}
