const IconCircleGenders = (props: { className?: string }) => (
  <svg viewBox="0 0 34 34" {...props}>
    <path
      d="M17 0c9.389 0 17 7.611 17 17s-7.611 17-17 17S0 26.389 0 17 7.611 0 17 0zm0 2C8.716 2 2 8.716 2 17c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"
      fill="currentColor"
      fillRule="nonzero"
    />
    <path
      d="M23.7 12.908v-3.42A.489.489 0 0 0 23.213 9h-3.42v.977h2.24l-2.64 2.64a5.105 5.105 0 0 0-3.263-1.175c-2.828 0-5.129 2.301-5.129 5.13a5.135 5.135 0 0 0 5.13 5.129 5.105 5.105 0 0 0 3.262-1.175l1.42 1.419-1.365 1.364.691.691 1.364-1.364L22.867 24l.69-.69-1.364-1.365 1.365-1.365-.691-.69-1.365 1.364-1.419-1.42a5.106 5.106 0 0 0 1.175-3.263 5.106 5.106 0 0 0-1.175-3.263l2.64-2.64v2.24h.978zm-7.57 7.816a4.157 4.157 0 0 1-4.153-4.152 4.157 4.157 0 0 1 4.152-4.153 4.157 4.157 0 0 1 4.152 4.153 4.157 4.157 0 0 1-4.152 4.152z"
      stroke="currentColor"
      strokeWidth=".5"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

export default IconCircleGenders
