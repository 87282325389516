import dynamic from 'next/dynamic'
import { PropsWithChildren } from 'react'

import { Layout as LayoutTypes } from 'types/layout'

import { IBaseLayoutProps } from './types'

export interface ILayoutProps extends IBaseLayoutProps {
  layoutType?: LayoutTypes
}

const MainLayout = dynamic(() => import('./components//MainLayout'))
const EmptyLayout = dynamic(() => import('./components/EmptyLayout'))
const CRMLayout = dynamic(() => import('./components/CRMLayout'))
const layoutMap = {
  [LayoutTypes.Main]: MainLayout,
  [LayoutTypes.Empty]: EmptyLayout,
  [LayoutTypes.CRM]: CRMLayout
}

const Layout = ({
  children,
  layoutType = LayoutTypes.Main,
  ...layoutProps
}: PropsWithChildren<ILayoutProps>) => {
  const LayoutComponent = layoutMap[layoutType]
  return <LayoutComponent {...layoutProps}>{children}</LayoutComponent>
}

export default Layout
