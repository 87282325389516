import { ComponentPropsWithoutRef } from 'react'

const SlidersIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.17071V0H2V3.17071C0.83481 3.58254 0 4.69378 0 6C0 7.3062 0.83481 8.4175 2 8.8293V18H4V8.8293C5.16519 8.4175 6 7.3062 6 6C6 4.69378 5.16519 3.58254 4 3.17071ZM2 6C2 5.44772 2.44772 5 3 5C3.55228 5 4 5.44772 4 6C4 6.55228 3.55228 7 3 7C2.44772 7 2 6.55228 2 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10.1707C7.83481 10.5825 7 11.6938 7 13C7 14.3062 7.83481 15.4175 9 15.8293V18H11V15.8293C12.1652 15.4175 13 14.3062 13 13C13 11.6938 12.1652 10.5825 11 10.1707V0H9V10.1707ZM9 13C9 12.4477 9.4477 12 10 12C10.5523 12 11 12.4477 11 13C11 13.5523 10.5523 14 10 14C9.4477 14 9 13.5523 9 13Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 5.17071V0H16V5.17071C14.8348 5.58254 14 6.69378 14 8C14 9.3062 14.8348 10.4175 16 10.8293V18H18V10.8293C19.1652 10.4175 20 9.3062 20 8C20 6.69378 19.1652 5.58254 18 5.17071ZM16 8C16 7.4477 16.4477 7 17 7C17.5523 7 18 7.4477 18 8C18 8.5523 17.5523 9 17 9C16.4477 9 16 8.5523 16 8Z"
      fill="currentColor"
    />
  </svg>
)

export default SlidersIcon
