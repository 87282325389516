const IconRelocation = (props: { className?: string }) => (
  <svg width="24px" height="24px" viewBox="0 0 512 512" {...props}>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
      <path d="M2350 4629 c-578 -65 -1098 -391 -1401 -879 -103 -167 -209 -419 -209 -500 0 -73 79 -150 154 -150 85 0 138 50 177 168 32 100 109 255 180 362 86 131 290 334 425 423 179 118 382 201 599 244 139 27 445 24 585 -6 477 -102 852 -374 1084 -789 82 -148 146 -323 171 -471 l7 -44 -32 23 c-43 31 -107 37 -154 15 -79 -37 -116 -134 -82 -207 10 -20 99 -119 199 -222 258 -263 245 -263 526 5 104 99 196 195 205 214 55 115 -49 245 -178 221 -22 -4 -55 -25 -90 -56 -29 -28 -57 -50 -60 -50 -3 0 -6 15 -6 33 0 54 -47 260 -85 374 -213 640 -764 1129 -1421 1262 -192 39 -415 50 -594 30z" />
      <path d="M2487 3470 c-21 -6 -52 -17 -69 -26 -54 -28 -541 -399 -579 -441 -75 -83 -74 -76 -74 -643 0 -475 1 -508 19 -555 26 -65 103 -147 169 -176 l52 -24 555 0 555 0 52 24 c66 29 143 111 169 176 18 47 19 80 19 555 0 567 1 560 -74 643 -47 53 -541 424 -596 448 -52 23 -146 32 -198 19z m314 -487 l238 -178 1 -442 0 -443 -160 0 -160 0 0 173 c0 159 -2 176 -22 217 -25 48 -89 90 -138 90 -49 0 -113 -42 -137 -90 -21 -41 -23 -58 -23 -217 l0 -173 -160 0 -160 0 0 443 1 442 237 177 c130 98 239 178 241 178 2 0 111 -80 242 -177z" />
      <path d="M745 2700 c-22 -11 -124 -103 -227 -203 -159 -156 -188 -187 -193 -219 -11 -61 2 -105 45 -148 70 -70 149 -67 234 10 29 28 57 50 60 50 3 0 6 -15 6 -32 0 -18 11 -86 25 -150 120 -578 490 -1061 1012 -1322 255 -127 493 -189 783 -203 670 -31 1325 314 1681 887 103 167 209 419 209 500 0 73 -79 150 -154 150 -85 0 -138 -50 -177 -168 -68 -211 -193 -407 -374 -588 -233 -234 -475 -364 -805 -435 -89 -20 -135 -23 -305 -23 -170 0 -216 3 -305 23 -542 116 -970 470 -1169 966 -38 95 -91 279 -91 318 0 17 2 17 31 -4 97 -69 236 -2 246 119 3 33 -1 57 -14 80 -22 39 -315 349 -360 381 -46 33 -108 37 -158 11z" />
    </g>
  </svg>
)

export default IconRelocation
