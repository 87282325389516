import dynamic from 'next/dynamic'
import { PropsWithChildren } from 'react'

import { IUseModal } from 'types/app'
import { Skeleton } from 'types/layout'

import CreateFormSkeleton from '../CreateFormSkeleton'

import useSkeleton from './useSkeleton'

const DefaultSkeleton = dynamic(
  () => import('components/skeleton/DefaultSkeleton')
)

const ModalAuth = dynamic(
  () => import('components/modals/ModalAuth/ModalAuth'),
  {
    ssr: false
  }
)

const skeletonsMap = {
  [Skeleton.Default]: DefaultSkeleton,
  [Skeleton.CreateForm]: CreateFormSkeleton
}
const SkeletonSelector = ({
  children,
  skeleton,
  showsForRoles,
  ...skeletonProps
}: PropsWithChildren<IUseModal>) => {
  const { type, showAuthModal, defaultModalType } = useSkeleton({
    showsForRoles
  })
  const SelectedSkeleton = skeletonsMap[skeleton || Skeleton.Default]

  if (showAuthModal) {
    return (
      <div className="blur transition-all">
        <SelectedSkeleton {...skeletonProps} />
        <ModalAuth
          type={type}
          isOpen={showAuthModal}
          defaultType={defaultModalType}
          onSuccess={() => {
            return
          }}
        />
      </div>
    )
  }
  return <>{children}</>
}

export default SkeletonSelector
