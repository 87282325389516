const primitiveTypes = ['string', 'bigint', 'number', 'boolean']

export const paramsSerializer = <T extends Record<string, unknown>>(
  params: T
): string => {
  try {
    return Object.entries(params)
      .reduce((acc, [type, item]) => {
        if (typeof item === 'undefined') {
          return acc
        }
        const values = (
          primitiveTypes.includes(typeof item) ? [item] : item
        ) as string[]

        values.forEach((query: string) => {
          acc.append(type, query)
        })
        return acc
      }, new URLSearchParams())
      .toString()
  } catch (e) {
    return ''
  }
}
