let canvas: HTMLCanvasElement | null = null

export const getApproximateTextWidth = (text: string, font: string) => {
  const fontSize = font.split(' ')[1] || ''
  const fontSizeValue = parseInt(fontSize) || 0

  const fontSizePx = fontSize.endsWith('px')
    ? fontSizeValue
    : fontSizeValue * 16

  const avgSymbolAspectRatio = 0.65
  const fontSymbolWidth = fontSizePx * avgSymbolAspectRatio
  return text.length * fontSymbolWidth
}

export const getTextWidth = (text: string, font: string) => {
  canvas ??= document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) {
    return getApproximateTextWidth(text, font)
  }
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}
export const getDigitsCount = (value: number) => {
  return Math.ceil(Math.log10(value + 1))
}
