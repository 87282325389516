export const splitStringIntoWords = (inputString: string, n: number) => {
  const words = inputString.split(/\s+/)
  const result = []
  let currentLine = ''

  for (const word of words) {
    if ((currentLine + ' ' + word).trim().length <= n) {
      if (currentLine === '') {
        currentLine = word
      } else {
        currentLine += ' ' + word
      }
    } else {
      result.push(currentLine)
      currentLine = word
    }
  }
  if (currentLine !== '') {
    result.push(currentLine)
  }

  return result
}

export const truncateSentence = (sentence: string, maxLength: number) => {
  if (sentence.length <= maxLength) {
    return sentence
  } else {
    const truncated = sentence.slice(0, maxLength)

    const lastSpaceIndex = truncated.lastIndexOf(' ')

    if (lastSpaceIndex !== -1) {
      return truncated.slice(0, lastSpaceIndex) + '...'
    } else {
      return sentence
    }
  }
}
