import clsx from 'clsx'

import styles from 'styles/Spinner.module.scss'

const Spinner = ({
  size = 180,
  isSM,
  isVisible = false,
  className,
  circleClasses
}: {
  size?: number
  isSM?: boolean
  isVisible?: boolean
  className?: string
  circleClasses?: string
}) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-center',
        className,
        isVisible ? 'visible' : 'invisible'
      )}
    >
      <div
        className={styles.spinner_box}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <div
          className={clsx(styles.circle_border, circleClasses)}
          style={{
            width: `${size / 2}px`,
            height: `${size / 2}px`,
            padding: `${isSM ? 6 : 3}px`
          }}
        >
          <div className={clsx(styles.circle_core, 'bg-white')}></div>
        </div>
      </div>
    </div>
  )
}

export default Spinner
