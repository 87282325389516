const IconFavorites = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M15.5 2c-1.74 0-3.41.81-4.5 2.09C9.91 2.81 8.24 2 6.5 2 3.42 2 1 4.42 1 7.5c0 3.78 3.4 6.86 8.55 11.54L11 20.35l1.45-1.32C17.6 14.36 21 11.28 21 7.5 21 4.42 18.58 2 15.5 2zm-4.4 15.55-.1.1-.1-.1C6.14 13.24 3 10.39 3 7.5 3 5.5 4.5 4 6.5 4c1.54 0 3.04.99 3.57 2.36h1.87C12.46 4.99 13.96 4 15.5 4c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
      fill="currentColor"
    />
  </svg>
)

export default IconFavorites
