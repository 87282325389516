import * as prismicH from '@prismicio/helpers'

import { AllDocumentTypes } from 'types/prismic'
import { prismicLangCodes } from 'constants/locales'
import { linkResolverWithLocale } from 'env/prismicio'

export const getPrismicLangCode = (locale: string | undefined) => {
  if (!locale) {
    return locale
  }
  const lang = prismicLangCodes[locale]
  if (lang) {
    return lang
  }
  return locale
}

export const getLocaleCodeFromPrismicLang = (lang: string | undefined) => {
  if (!lang || lang.length < 5) {
    return lang
  }
  const [locale] = lang.split('-')
  if (locale) {
    return locale
  }
  return lang
}

export const documentsToPaths = (documents: AllDocumentTypes[]) =>
  documents.reduce<string[]>((acc, doc) => {
    const link = prismicH.asLink(doc, linkResolverWithLocale)
    if (link) {
      acc.push(link)
    }
    return acc
  }, [])
