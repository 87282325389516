const IconDownload = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" style={{ width: '24px', height: '24px' }} {...props}>
    <path
      d="M5 15v5h14v-5h2v7H3v-7h2zm8-13v12.16l2.938-2.937 1.414 1.414-3.944 3.944.006.006L12 18.002 6.648 12.65l1.415-1.414L11 14.173V2h2z"
      fill="currentColor"
    />
  </svg>
)

export default IconDownload
