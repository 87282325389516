import { Bounded } from 'components/Bounded'
import { Button } from 'components/buttons'
import Steps from 'components/Steps'
import { IconArrow } from 'icons'

import { ISkeletonProps } from './SkeletonSelector/types'

const InputStyle = {
  fieldSimple:
    'focus:shadow-outline block h-full w-full appearance-none border-none bg-transparent py-2 px-4 text-gray-700 transition-all focus:shadow-transparent focus:outline-none',
  info: 'absolute text-xs tracking-wider antialiased pt-1',
  helper: 'fade-in text-gray-400 text-xs tracking-wider antialiased',
  error:
    'fade-in font-medium text-error-400 text-xs tracking-wider antialiased',
  input:
    'block h-10 w-full rounded-sm border-0 border-b-2 border-gray-200 focus:border-gray-400 text-gray-700 transition-colors focus:border-black',
  label: 'block pt-4 pb-2 text-lg font-semibold tracking-wide text-gray-900',
  fieldWrap: 'relative',
  required: 'ml-1 font-bold text-red-550'
}

const InputTemplate = () => (
  <div className={InputStyle.fieldWrap}>
    <label className={InputStyle.label}>
      Label
      <span className={InputStyle.required}>*</span>
    </label>
    <input className={InputStyle.input} placeholder="placeholder" type="text" />
    <div className={InputStyle.info}>
      <div className={InputStyle.helper}>helping text</div>
    </div>
  </div>
)

const CardTemplate = () => (
  <div className='transition-transform" relative my-5 border border-gray-200 bg-white'>
    <div className="z-[2] flex w-full items-center justify-between border-b-0 bg-white py-10 px-10 outline-none transition-all">
      <div className="text-left">
        <h5 className="text-2xl font-bold">Lorem Ipsum</h5>
        <h6 className="text-lg text-slate-400">
          Qui consectetur eum sed placeat sapiente veritatis rerum ea.
        </h6>
      </div>
      <div className="text-xl font-semibold uppercase">EDIT</div>
    </div>
  </div>
)

const CreateFormSkeleton = ({ title }: ISkeletonProps) => {
  return (
    <Bounded className="bg-gray-50 px-5 xl:px-0">
      <section className="w-full pt-10">
        <div className="flex items-center justify-between ">
          <h2 className="pb-2 text-5xl font-bold">
            {title ?? 'Title'}
            <i className="dot" />
          </h2>
        </div>
        <div>
          <Steps
            activeStep={0}
            steps={['Step 1', 'Step 2', 'Step 3', 'Step 4']}
          />
        </div>
        <div className='transition-transform" relative my-5 border border-gray-200 bg-white'>
          <div className="z-[2] flex w-full items-center justify-between border-b-0 bg-white px-10 pt-10 outline-none transition-all">
            <div className="text-left">
              <h5 className="text-2xl font-bold">Lorem Ipsum</h5>
              <h6 className="text-lg text-slate-400">
                Qui consectetur eum sed placeat sapiente veritatis rerum ea.
              </h6>
            </div>
            <div className="text-xl font-semibold uppercase">EDIT</div>
          </div>
          <div className="z-[1] px-10 pb-10">
            <p className="mt-10 text-gray-500">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Accusantium, fugiat?
            </p>
            <div className="mb-5 mt-5 grid grid-cols-1 gap-x-5  gap-y-4 md:grid-cols-2 lg:grid-cols-3">
              {[0, 1, 2, 3, 4, 5].map((i) => (
                <InputTemplate key={i} />
              ))}
            </div>
            <div className="flex justify-end">
              <Button>
                <span>action</span>
                <IconArrow className="ml-2 h-7 w-7" />
              </Button>
            </div>
          </div>
        </div>
        <CardTemplate />
        <CardTemplate />
      </section>
    </Bounded>
  )
}

export default CreateFormSkeleton
