import { ElementType, PropsWithChildren } from 'react'
import clsx from 'clsx'

export enum BoundedVariant {
  None,
  Small,
  Base
}
export interface IBoundedProps extends PropsWithChildren {
  as?: ElementType
  variant?: BoundedVariant
  collapsible?: boolean
  className?: string
}

export const Bounded = ({
  as: Comp = 'div',
  variant,
  collapsible = true,
  className,
  children
}: IBoundedProps) => {
  if (variant === BoundedVariant.None) {
    return <>{children}</>
  }
  return (
    <Comp
      data-collapsible={collapsible}
      className={clsx(
        variant === BoundedVariant.Small && 'py-8 sm:px-6 md:py-10',
        variant === BoundedVariant.Base && 'px-6 py-20 md:py-32',
        className
      )}
    >
      <div className="mx-auto w-full max-w-6xl">{children}</div>
    </Comp>
  )
}
