import { IMarkupSchemaDataProps } from '../types'

const JobPostingSchema = ({ data }: IMarkupSchemaDataProps<'JobPosting'>) => {
  const {
    title,
    description,
    identifier,
    datePosted,
    validThrough,
    applicantLocationRequirements,
    jobLocationType,
    employmentType,
    hiringOrganization,
    baseSalary,
    jobLocation
  } = data

  let schema: Record<string, unknown> = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    title,
    description,
    identifier: { '@type': 'PropertyValue', ...identifier },
    datePosted,
    applicantLocationRequirements: {
      '@type': 'Country',
      name: applicantLocationRequirements
    },
    employmentType,
    hiringOrganization: {
      '@type': 'Organization',
      ...hiringOrganization
    },
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'EUR',
      value: {
        '@type': 'QuantitativeValue',
        unitText: 'MONTH',
        ...baseSalary
      }
    }
  }

  if (validThrough) {
    schema = { ...schema, validThrough }
  }
  if (jobLocationType) {
    schema = { ...schema, jobLocationType }
  }
  if (jobLocation) {
    schema = {
      ...schema,
      jobLocation: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          ...jobLocation
        }
      }
    }
  }
  return schema
}

export default JobPostingSchema
