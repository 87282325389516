import type { AppEnv, IEnv } from './env.type'

// const mapEnvValues = {
//   number: (envValue: string, defaultValue: number) => {
//     const value = Number(envValue)

//     return Number.isNaN(value) ? defaultValue : value
//   }
//   //..another mappings ex. boolean
// }

const mapEnv = () => {
  const parsed: IEnv = {
    apiUrl: process.env.API_URL || '',
    adminEmail: process.env.ADMIN_EMAIL || '',
    adminPass: process.env.ADMIN_PASS || '',
    adminJwt: process.env.ADMIN_JWT || '',
    revalidateKey: process.env.REVALIDATE_PRISMIC_KEY || '',
    authTokenName: process.env.NEXT_PUBLIC_AUTH_TOKEN || 'hrizer-auth',
    captchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_KEY || '',
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL || '',
    appEnv: (process.env.NEXT_PUBLIC_APP_ENV as AppEnv) || 'staging'
  }

  return Object.freeze(parsed)
}

let env: IEnv
export const getEnv = (): Readonly<IEnv> => {
  if (!env) {
    env = mapEnv()
  }
  return env
}
