import { ComponentPropsWithoutRef } from 'react'

const IconFilter = ({
  width = 24,
  height = 24,
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg viewBox="0 0 24 24" width={width} height={height} {...props}>
    <path
      d="m1 1 6.401 9.31v7.367l7 2.8V10.311L20.802 1H1zm3.802 2H17l-4.599 6.69v7.833l-3-1.2V9.689L4.802 3z"
      fill="currentColor"
    />
  </svg>
)

export default IconFilter
