const IconLightbulb = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C7.58172 2 4 5.58172 4 10C4 11.7994 4.59525 13.4626 5.59918 14.7996C6.09407 15.4587 6.66438 16.0336 7.19746 16.5572C7.27017 16.6286 7.34203 16.6989 7.41301 16.7684L7.41353 16.7689C7.87797 17.2233 8.30525 17.6413 8.69226 18.0941L9.03926 18.5H14.9607L15.3077 18.0941C15.6947 17.6414 16.122 17.2233 16.5865 16.7689C16.6576 16.6993 16.7297 16.6288 16.8025 16.5572C17.3356 16.0336 17.9059 15.4587 18.4008 14.7996C19.4047 13.4626 20 11.7994 20 10C20 5.58172 16.4183 2 12 2ZM6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10C18 11.3515 17.5543 12.5961 16.8015 13.5987C16.4053 14.1263 15.931 14.6099 15.4012 15.1303C15.3333 15.1969 15.2642 15.2644 15.1943 15.3327C14.8216 15.6972 14.4241 16.0857 14.0475 16.5H9.95255C9.57586 16.0857 9.17841 15.6972 8.80566 15.3327L8.80515 15.3322C8.73542 15.2641 8.66655 15.1967 8.59884 15.1303C8.06901 14.6099 7.59471 14.1263 7.19849 13.5987C6.44568 12.5961 6 11.3515 6 10Z"
      fill="currentColor"
    />
    <path d="M15 22V20H9V22H15Z" fill="currentColor" />
  </svg>
)

export default IconLightbulb
