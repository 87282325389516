import { IUserMatchesRequest, IUserMatchRequest } from 'data-transfers/requests'

export const messageKeys = {
  all: ['all_messaging'] as const,
  userRepresentation: (userId: string) => [
    ...messageKeys.all,
    'user-representation',
    userId
  ],
  userRepresentations: (userIds: string[]) => [
    ...messageKeys.all,
    'user-representations',
    ...userIds
  ],
  matches: (payload: IUserMatchesRequest) => [
    ...messageKeys.all,
    'user-matches',
    payload
  ],
  match: (payload: IUserMatchRequest) => [
    ...messageKeys.all,
    'user-match',
    payload
  ],
  firebaseToken: () => [...messageKeys.all, 'firebase_token'] as const
}
