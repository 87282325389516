const IconUpload = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" style={{ width: '24px', height: '24px' }} {...props}>
    <path
      fill="currentColor"
      d="M17.352 7.352 13 3v-.01h-.01L12 2l-.99.99H11V3L6.648 7.352l1.415 1.414L11 5.828V16.99h2V5.83l2.938 2.937z"
    />
    <path fill="currentColor" d="M5 19.99v-5H3v7h18v-7h-2v5z" />
  </svg>
)

export default IconUpload
