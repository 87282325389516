import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

const Steps = ({
  activeStep,
  steps
}: {
  activeStep: number
  steps: string[]
}) => {
  const [offset, setOffset] = useState<number>(0)
  const firstStep = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // firstStep.current?.scrollIntoView({ behavior: 'smooth' })
    if (firstStep.current) {
      setOffset(firstStep.current?.offsetLeft * -1 + 4)
    }
    const resizeFn = () => {
      if (firstStep.current) {
        setOffset(firstStep.current?.offsetLeft * -1 + 4)
      }
    }
    window.addEventListener('resize', resizeFn, true)

    return () => {
      removeEventListener('resize', resizeFn)
    }
  }, [])

  // useEffect(() => {
  //   if (document) {
  //     const step = document.getElementById(`steps-${activeStep}`)
  //     if (step) {
  //       step.scrollIntoView({ behavior: 'smooth' })
  //     }
  //   }
  // }, [activeStep])

  // max-w-[calc(100vw_-_80px)]
  return (
    <div
      className="fade-in-delay my-5 flex max-w-[calc(100vw_-_10px)] overflow-x-auto opacity-0 transition-transform"
      style={{
        transform: `translateX(${offset}px)`
      }}
    >
      {steps.map((step, index) => {
        const isActive = activeStep === index
        const isLast = index === steps.length - 1
        const isFirst = index === 0
        const isPast = activeStep > index
        return (
          <div
            key={step}
            id={`step-${index}`}
            className="relative w-[10vw] min-w-[100px] max-w-[200px] md:w-[15vw] md:min-w-[120px]"
          >
            <div
              className={clsx(
                'absolute left-[50%] top-3 h-1 w-[10vw] min-w-[100px] max-w-[200px] translate-y-[-50%] md:top-4 md:w-[15vw] md:min-w-[120px] ',
                isPast ? 'bg-red-550' : 'bg-black',
                isLast && 'hidden'
              )}
            ></div>
            <div
              className={clsx(
                'relative mx-auto flex h-6 w-6 items-center justify-center rounded-full border-4 bg-white transition-all  md:h-8 md:w-8',
                isPast ? 'border-red-550' : 'border-black',
                isActive && 'border-red-550'
              )}
            >
              <div
                className={clsx(
                  'h-3 w-3 rounded-full bg-red-550 transition-all md:h-4 md:w-4',
                  !isActive && 'bg-red-550/0'
                )}
              ></div>
            </div>
            <div
              ref={isFirst ? firstStep : undefined}
              className={clsx(
                'mx-auto mt-1 w-fit text-center text-sm uppercase transition-all',
                isActive && 'font-bold'
              )}
            >
              {step}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Steps
