import { getEnv } from 'utils/env/env.util'

import { IMarkupSchemaDataProps, MarkupSchemaType } from '../types'

const type: MarkupSchemaType = 'BlogPosting'
const BlogPostingSchema = ({ data }: IMarkupSchemaDataProps<typeof type>) => {
  const { baseUrl } = getEnv()
  const { author, ...params } = data
  return {
    '@context': 'https://schema.org',
    '@type': type,
    ...params,
    author: author.map((x) => ({
      '@type': 'Person',
      url: baseUrl,
      ...x
    })),
    publisher: {
      '@type': 'Organization',
      name: 'HRizer',
      logo: `${baseUrl}/img/logo.png`
    }
  }
}

export default BlogPostingSchema
