import { useQuery } from '@tanstack/react-query'
import { INotificationDto } from 'data-transfers/dto/notification'

import { IUseQueryHook } from 'hooks/types'

import { userProfileKeys } from './keys'
import { getNotificationToShown } from './profile.service'

const useNotifications = ({
  options
}: IUseQueryHook<void, INotificationDto | null>) => {
  return useQuery(
    userProfileKeys.notifications(),
    async () => {
      const response = await getNotificationToShown()
      return response
    },
    {
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      ...options
    }
  )
}
export default useNotifications
