const IconDocument = (props: { className?: string }) => (
  <svg viewBox="0 0 22 22" {...props}>
    <path d="M15,10H7v2h8V10z" fill="currentColor" />
    <path d="M7,6h6v2H7V6z" fill="currentColor" />
    <path d="M15,14H7v2h8V14z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4,1H3v20h16V5.6L14.4,1z M5,19V3h8.6L17,6.4V19H5z"
      fill="currentColor"
    />
  </svg>
)

export default IconDocument
