import clsx from 'clsx'

import Button from '../Button'

import { IButtonIconProps } from './types'

const ButtonIcon = ({
  icon: Icon,
  rounded,
  className,
  children,
  ...rest
}: IButtonIconProps) => (
  <Button
    className={clsx(
      'border-none px-1 py-1',
      rounded && 'rounded-full',
      className
    )}
    {...rest}
  >
    {Icon ? <Icon /> : children}
  </Button>
)

export default ButtonIcon
