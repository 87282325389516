import Head from 'next/head'
import { useTranslation } from 'next-i18next'

import { getEnv } from 'utils/env/env.util'
import metaTags from 'constants/meta-tags.constants'

import { IMetaTagsProps } from './types'

const { siteName, ogLocale, ogLocaleAlternate } = metaTags
const { baseUrl } = getEnv()

const defaultImage = {
  copyright: null,
  url: `${baseUrl}/img/shared_image.jpeg`,
  dimensions: { width: 1200, height: 670 },
  alt: `Hrizer - AI Based Hiring Tool`
}

const MetaTags = ({
  metaProps,
  disableIndexing,
  isProd,
  fbSiteVerification,
  gsv,
  title = 'Hrizer - AI Based Hiring Tool',
  metaDescription = 'Hrizer - AI Based Hiring Tool',
  ogImage = defaultImage,
  twitterCard = 'summary_large_image',
  ...seo
}: IMetaTagsProps) => {
  const { t } = useTranslation(['meta'])

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {isProd && (
        <>
          {gsv && <meta name="google-site-verification" content={gsv} />}
          {fbSiteVerification && (
            <meta
              name="facebook-domain-verification"
              content={fbSiteVerification}
            />
          )}
        </>
      )}
      <meta
        property={siteName.property}
        content={siteName.content}
        key={siteName.key}
      />
      <meta
        property={ogLocale.property}
        content={ogLocale.content}
        key={ogLocale.key}
      />
      <meta
        property={ogLocaleAlternate.property}
        content={ogLocaleAlternate.content}
        key={ogLocaleAlternate.key}
      />
      {title && (
        <>
          <title key="title">
            {t(title, { ns: 'meta', defaultValue: title })}
          </title>
          <meta
            key="og:title"
            property="og:title"
            content={t(title, { ns: 'meta', defaultValue: title })}
          />
          <meta
            key="twitter:title"
            name="twitter:title"
            content={t(title, { ns: 'meta', defaultValue: title })}
          />
        </>
      )}
      {metaDescription && (
        <>
          <meta
            key="description"
            name="description"
            content={t(metaDescription, { ns: 'meta' })}
          />
          <meta
            key="og:description"
            property="og:description"
            content={t(metaDescription, { ns: 'meta' })}
          />
          <meta
            key="twitter:description"
            name="twitter:description"
            content={t(metaDescription, { ns: 'meta' })}
          />
        </>
      )}

      {seo.keywords && (
        <meta name="keywords" content={seo.keywords} key="keywords" />
      )}
      {ogImage?.url && (
        <>
          <meta property="og:image" content={ogImage.url} key="og:image" />
          <meta
            property="og:image:secure_url"
            content={ogImage.url}
            key="og:image:secure_url"
          />
          <meta
            name="twitter:image"
            content={ogImage.url}
            key="twitter:image"
          />
        </>
      )}
      {ogImage?.dimensions?.width && (
        <>
          <meta
            property="og:image:width"
            content={ogImage.dimensions.width.toString()}
            key="og:image:width"
          />
          <meta
            name="twitter:image:width"
            content={ogImage.dimensions.width.toString()}
            key="twitter:image:width"
          />
        </>
      )}
      {ogImage?.dimensions?.height && (
        <>
          <meta
            property="og:image:height"
            content={ogImage.dimensions.height.toString()}
            key="og:image:height"
          />
          <meta
            name="twitter:image:height"
            content={ogImage.dimensions.height.toString()}
            key="twitter:image:height"
          />
        </>
      )}
      {ogImage?.alt && (
        <>
          <meta
            property="og:image:alt"
            content={ogImage.alt}
            key="og:image:alt"
          />
          <meta
            name="twitter:image:alt"
            content={ogImage.alt}
            key="twitter:image:alt"
          />
        </>
      )}

      {twitterCard && (
        <meta name="twitter:card" content={twitterCard} key="twitter:card" />
      )}
      {seo?.twitterSite && (
        <meta
          name="twitter:site"
          content={seo.twitterSite}
          key="twitter:site"
        />
      )}
      {seo?.twitterCreator && (
        <meta
          name="twitter:creator"
          content={seo.twitterCreator}
          key="twitter:creator"
        />
      )}

      {seo?.fbAppId && <meta name="fb:app_id" content={seo.fbAppId} />}
      {disableIndexing && <meta name="robots" content="noindex, nofollow" />}
      {metaProps?.map(({ property, content, key }, idx) => (
        <meta property={property} content={content} key={key ?? idx} />
      ))}
    </Head>
  )
}

export default MetaTags
