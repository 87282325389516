const IconLogo = (props: { className?: string }) => (
  <svg viewBox="0 0 111 54" width="111px" height="54px" {...props}>
    <circle fill="#E4002B" fillRule="nonzero" cx="27" cy="27" r="27" />
    <path
      d="m11.04 18.06-.02 9.337-.02 9.278h2.113l.021-4.01.02-4.01h10.137l.02 4.01.02 4.01h2.154V18H23.33v8.54H13.115V18h-1.038c-.57 0-1.037.027-1.037.06zm17.318 9.278v9.337H30.513l.002-.22-.002-3.98c-.003-3.377.01-3.768.125-3.812.201-.077 5.525-.053 6.083.027.971.14 1.632.645 2.243 1.714.267.466 1.304 3.057 1.888 4.715.061.175.216.588.343.918l.232.598 1.167.022c.642.013 1.167.004 1.167-.019s-.087-.266-.193-.54a984.08 984.08 0 0 1-1.25-3.254c-1.352-3.547-1.492-3.85-2.056-4.43-.15-.156-.26-.295-.243-.31.017-.017.248-.138.514-.271 1.087-.545 1.873-1.435 2.298-2.603.21-.578.239-.771.272-1.845.03-.948.01-1.307-.093-1.716-.393-1.543-1.364-2.606-2.92-3.198-.216-.081-.803-.22-1.307-.31-.85-.15-1.253-.161-5.67-.161h-4.755v9.338zm10.372-7c.708.212 1.404.593 1.635.896.55.72.725 1.741.492 2.867-.212 1.022-.604 1.542-1.458 1.935-.87.4-1.313.44-5.235.48l-3.65.037v-6.41l3.89.033c3.293.028 3.957.053 4.326.163z"
      fill="#FFF"
    />
    <path
      d="M59.66 18.05c-.031.031-.056 4.234-.056 9.34v9.285h2.075l-.02-9.317-.02-9.318-.962-.022c-.53-.013-.987.002-1.018.033zm5.071.003c-.072.029-.1.311-.1 1.023 0 .831.02.99.13 1.032.07.027 2.432.048 5.247.048 4.029-.001 5.113.02 5.092.097-.023.087-.603.831-1.103 1.417-.323.377-1.105 1.332-2.582 3.156a1851.174 1851.174 0 0 1-4.124 5.076 685.79 685.79 0 0 1-2.57 3.142L63.6 34.4l-.003 1.137-.004 1.137H78.119v-2.074l-5.98-.02-5.981-.02.52-.64c.287-.35.64-.781.786-.957.448-.537 2.772-3.384 3.153-3.862.586-.734 1.833-2.27 1.983-2.443.077-.088.781-.95 1.567-1.915.785-.966 1.667-2.043 1.96-2.395.294-.35.79-.959 1.103-1.35l.57-.714V18l-6.485.006c-3.566.004-6.529.025-6.584.047zm15.357 0c-.03.03-.054 4.231-.054 9.338v9.284h13.69l.024-.877c.014-.483.002-.95-.026-1.038-.051-.158-.115-.16-5.832-.16h-5.78V28.535h10.454V26.46H82.108l.02-3.157.021-3.156 5.567.004 5.567.004V18h-6.571c-3.614 0-6.595.024-6.624.053zm15.648-.005c-.033.033-.06 4.237-.059 9.343l.002 9.284h2.073V28.606l3.332.024c3.271.024 3.338.028 3.691.204.452.226.777.511 1.153 1.011.462.615.695 1.14 1.714 3.859.524 1.397.997 2.637 1.051 2.756l.098.215h1.105c.607 0 1.104-.015 1.104-.034 0-.03-.358-.975-1.452-3.837-.135-.35-.326-.854-.426-1.117-.859-2.268-1.029-2.606-1.629-3.258l-.319-.346.44-.182c.943-.391 1.893-1.341 2.327-2.328.698-1.585.619-3.786-.184-5.157-.662-1.128-1.772-1.834-3.452-2.193-.72-.154-1.097-.168-5.64-.2-2.678-.02-4.896-.009-4.929.025zm10.06 2.23c1.23.249 2.011.811 2.308 1.664.217.622.186 2.081-.059 2.72-.244.639-.836 1.233-1.461 1.466-.945.354-1.689.41-5.38.411h-3.452v-6.384h3.714c3.042 0 3.826.022 4.33.124z"
      fill="#101820"
    />
  </svg>
)

export default IconLogo
