const CompanyIcon = (props: { className?: string }) => (
  <svg viewBox="0 0 24 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.8,0h6.4l7.9,7H0.9L8.8,0z M9.5,2L6.1,5h11.7l-3.4-3H9.5z"
      fill="currentColor"
    />
    <path d="M4,18h16v2H4V18z" fill="currentColor" />
    <path d="M13,16.5v-8h-2v8H13z" fill="currentColor" />
    <path d="M17,8.5v8h-2v-8H17z" fill="currentColor" />
    <path d="M9,16.5v-8H7v8H9z" fill="currentColor" />
  </svg>
)

export default CompanyIcon
