const IconCheck = (props: { className?: string }) => (
  <svg viewBox="0 0 33 34" name="IconCheck" {...props}>
    <path
      fill="currentColor"
      d="m25.08 11.414-10.96 10.96L8 16.254l1.414-1.414 4.706 4.706L23.666 10z"
    />
  </svg>
)

export default IconCheck
