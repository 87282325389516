import {
  ChatStatus,
  ICreateRoomProps,
  IRoomFullInfoProps,
  IRoomProps
} from 'components/Messages/components/RoomList/types'
import { FetchChatStatus } from 'components/Messages/hooks/types'

import { UserRole } from 'constants/auth'

import { isOrgRole } from './role.util'

interface IGetEntityByRoleProps {
  userId: string
  recipientId: string
  role: UserRole
  orgId: string | null
}

const getSearchedByUserField = (
  role: UserRole,
  {
    candidateId,
    orgUserId
  }: Pick<ICreateRoomProps, 'candidateId' | 'orgUserId'>
) => {
  switch (role) {
    case UserRole.Candidate:
      return {
        fieldName: 'candidateId',
        value: candidateId,
        fieldNameMatch: 'orgUserId',
        valueMatch: orgUserId,
        chatStatus: 'chatStatusCandidate'
      }
    case UserRole.Organisation:
    case UserRole.OrganisationEmployee:
      return {
        fieldName: 'orgUserId',
        value: orgUserId,
        fieldNameMatch: 'candidateId',
        valueMatch: candidateId,
        chatStatus: 'chatStatusOrg'
      }
  }

  throw new Error('User role not found')
}

const getByField = ({
  role,
  userId,
  orgId
}: Omit<IGetEntityByRoleProps, 'recipientId'>) => {
  if ([UserRole.Organisation, UserRole.OrganisationEmployee].includes(role)) {
    return orgId
      ? { fieldName: 'orgId', value: orgId }
      : { fieldName: 'employerId', value: userId }
  }
  return { fieldName: 'candidateId', value: userId }
}

const isUserOnline = ({
  presenceStatus
}: Partial<Pick<IRoomFullInfoProps, 'presenceStatus'>>) => {
  if (presenceStatus && presenceStatus.state === 'online') {
    return true
  }

  return false
}

const filteredStatus: Record<FetchChatStatus, ChatStatus[]> = {
  all: ['archived', 'active', 'unread'],
  archived: ['archived'],
  unread: ['archived', 'active', 'unread'],
  active: ['active']
}

const filterByStatusChat = (
  rooms: IRoomProps[],
  status: FetchChatStatus,
  senderRole?: UserRole
) => {
  if (!senderRole) {
    return []
  }
  let field: keyof Pick<IRoomProps, 'chatStatusCandidate' | 'chatStatusOrg'> =
    'chatStatusCandidate'
  if (isOrgRole(senderRole)) {
    field = 'chatStatusOrg'
  }

  return rooms.filter(
    (room) =>
      room[field] && filteredStatus[status].includes(room[field] as ChatStatus)
  )
}

const getSenderRecipientIds = (
  {
    candidateId,
    orgUserId
  }: Pick<ICreateRoomProps, 'candidateId' | 'orgUserId'>,
  senderId: string
) => {
  if (senderId === candidateId) {
    return {
      senderId,
      recipientId: orgUserId,
      recipientRole: UserRole.Organisation,
      senderRole: UserRole.Candidate
    }
  }

  return {
    recipientId: candidateId,
    senderId,
    recipientRole: UserRole.Candidate,
    senderRole: UserRole.Organisation
  }
}

const messageUtil = {
  getByField,
  getSearchedByUserField,
  isUserOnline,
  filterByStatusChat,
  getSenderRecipientIds
}

export default messageUtil
