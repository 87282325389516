const IconCircleGenders = (props: { className?: string }) => (
  <svg viewBox="0 0 34 34" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h34v34H0z" />
      <path
        d="M17 0c9.389 0 17 7.611 17 17s-7.611 17-17 17S0 26.389 0 17 7.611 0 17 0zm0 2C8.716 2 2 8.716 2 17c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"
        fill="#08081F"
        fillRule="nonzero"
      />
      <path
        stroke="#08081F"
        strokeWidth="1.3"
        d="M10.65 17.65h2.7v4.7h-2.7zM15.65 9.65h2.7v12.7h-2.7zM20.65 13.65h2.7v8.7h-2.7z"
      />
    </g>
  </svg>
)

export default IconCircleGenders
