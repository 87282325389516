import { IAvailableSubscriptionsResponse } from 'data-transfers/responses'

import { paidPlans } from 'constants/subscription'

export interface IPlanPrice {
  price: number
  planId: string
}
export type IMappedPricing = Record<
  (typeof paidPlans)[number],
  { fortnight: IPlanPrice; monthly: IPlanPrice; yearly: IPlanPrice }
>
export const mapPricing = (
  subscriptionPrices: IAvailableSubscriptionsResponse
) =>
  subscriptionPrices.reduce((acc, item) => {
    const plan = { price: item.price, planId: item.id }
    if (!item.periodMonths) {
      acc[item.name] = {
        ...acc[item.name],
        fortnight: plan
      }
    }
    if (item.periodMonths === 1) {
      acc[item.name] = {
        ...acc[item.name],
        monthly: plan
      }
    }
    if (item.periodMonths === 12) {
      acc[item.name] = {
        ...acc[item.name],
        yearly: plan
      }
    }
    return acc
  }, {} as IMappedPricing)
