import * as React from 'react'

import { TooltipOptions } from './types'
import useTooltip from './useTooltip'
import { TooltipContext } from './useTooltipContext'

const Tooltip = ({
  children,
  ...options
}: { children: React.ReactNode } & TooltipOptions) => {
  const tooltip = useTooltip(options)
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  )
}

export default Tooltip
