import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import { IconArrow } from 'icons'

import { ISectionEditButtonProps, variantIcons } from './types'

export const SectionEditButton = ({
  variant = 'edit',
  isPlain,
  className,
  showText,
  arrowIcon,
  ...rest
}: ISectionEditButtonProps) => {
  const { t } = useTranslation(['actions'])
  const Icon = variantIcons[variant]
  return (
    <button
      {...rest}
      className={clsx(
        isPlain
          ? 'underline-b hover:text-red-550 active:text-red-550 '
          : 'flex items-center border-2 border-black p-2 transition-all hover:border-red-550 hover:bg-red-550 hover:text-white active:border-red-600 active:bg-red-600 active:text-white',
        className
      )}
    >
      <Icon className={isPlain ? 'h-5 w-5' : 'h-[18px] w-[18px]'} />
      {showText && (
        <span className="ml-2 font-semibold">
          {t('edit', { ns: 'actions' })}
        </span>
      )}
      {arrowIcon && (
        <span className="ml-2">
          <IconArrow />
        </span>
      )}
    </button>
  )
}
export default SectionEditButton
