export const MIN_PASSWORD_LENGTH = 8
export const MAX_PASSWORD_LENGTH = 36

export enum UserRole {
  Admin,
  Candidate,
  Organisation,
  OrganisationEmployee
}

export enum AuthAccessLevel {
  Public,
  Authorized,
  Unauthorized
}
