export enum OnBoardingStatus {
  Initial,
  PrivacyPolicy = 1,
  PersonalInformation = 10,
  PersonalPhoto = 20,
  Education = 30,
  WorkExperience = 40,
  Languages = 50,
  JobOfferPreferences = 60,
  AutoApply = 65,
  OnPublish = 70,
  Recheck = 75,
  Completed = 80
}

export enum OrganisationOnboardingStatus {
  OverallInformation = 0,
  Address = 10,
  ShowInformation = 20,
  Languages = 30,
  Description = 40,
  SocialNetworks = 50,
  Logo = 60,
  Photo = 70,
  Gallery = 80,
  PrivacyPolicy = 90,
  Subscription = 95,
  Payment = 100,
  PaymentPending = 105,
  NewPending = 110,
  UpdatePending = 120,
  Approved = 130,
  Rejected = 140
}
