import { ComponentPropsWithoutRef } from 'react'

const UserIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg viewBox="0 0 25 25" {...props}>
    <path
      fill="currentColor"
      d="M12.632 1.09C9.778 1.09 7.464 3.29 7.464 6c0 2.711 2.314 4.91 5.168 4.91S17.799 8.71 17.799 6c0-2.711-2.313-4.91-5.167-4.91zM9.76 6c0-1.506 1.285-2.727 2.87-2.727 1.586 0 2.871 1.22 2.871 2.727 0 1.506-1.285 2.727-2.87 2.727-1.586 0-2.871-1.22-2.871-2.727zM12.632 12.545c-5.074 0-9.187 3.908-9.187 8.728v1.636h18.373v-1.636c0-4.82-4.113-8.728-9.186-8.728zm0 2.182c3.611 0 6.574 2.64 6.866 6H5.765c.292-3.36 3.255-6 6.867-6z"
    />
  </svg>
)

export default UserIcon
