import { toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'
import { IError } from 'data-transfers/dto/error'

const getErrorMessage = (error: Error | AxiosError | unknown) => {
  if (axios.isAxiosError(error)) {
    const res = error?.response?.data as
      | (IError & { title?: string })
      | undefined
    if (res?.title?.length) {
      return res.title
    }

    return res?.message || error.message
  }
  if (!axios.isAxiosError(error) && error instanceof Error && error.message) {
    return error.message
  }
  return ''
}

export const handleQueryError = (error: Error | AxiosError | unknown) => {
  toast.error(getErrorMessage(error))
}
export const handleError = ({
  error,
  extraMessage,
  defaultMessage
}: {
  error: Error | AxiosError | unknown
  extraMessage?: string
  defaultMessage?: string
}) => {
  const errorMessage = getErrorMessage(error)
  const message = errorMessage.length ? errorMessage : defaultMessage
  const fullMessage = extraMessage?.length
    ? `${message} ${extraMessage}`
    : message
  toast.error(fullMessage)
}
