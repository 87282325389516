import * as React from 'react'
import { FloatingArrow, FloatingPortal, useMergeRefs } from '@floating-ui/react'
import clsx from 'clsx'

import useTooltipContext from './useTooltipContext'

const ARROW_WIDTH = 20
const ARROW_HEIGHT = 10

const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ className, children }, propRef) {
  const context = useTooltipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!context.open) return null

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{ ...context.floatingStyles, zIndex: 50 }}
        className={clsx(
          'break-normal rounded bg-white p-2 shadow-md shadow-gray-300 outline outline-1 outline-offset-0 outline-gray-400',
          className
        )}
      >
        {children}
        <FloatingArrow
          ref={context.arrowRef}
          context={context.context}
          width={ARROW_WIDTH}
          height={ARROW_HEIGHT}
          strokeWidth={1}
          className="fill-white [&>path:first-of-type]:stroke-gray-400 [&>path:last-of-type]:stroke-white"
        />
      </div>
    </FloatingPortal>
  )
})

export default TooltipContent
