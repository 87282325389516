const IconCircleTeam = (props: { className?: string }) => (
  <svg viewBox="0 0 34 34" {...props}>
    <path
      d="M17 0c9.389 0 17 7.611 17 17s-7.611 17-17 17S0 26.389 0 17 7.611 0 17 0zm0 2C8.716 2 2 8.716 2 17c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"
      fill="currentColor"
      fillRule="nonzero"
    />
    <path
      d="M26.273 18.903a3.76 3.76 0 0 0-2.304-3.465 2.461 2.461 0 1 0-2.934 0 3.741 3.741 0 0 0-1.988 2.028l-.453-.236a2.46 2.46 0 1 0-2.934 0l-.453.236a3.741 3.741 0 0 0-1.989-2.009 2.462 2.462 0 1 0-2.934 0A3.76 3.76 0 0 0 8 18.903v.886h.788v-.886a2.973 2.973 0 0 1 5.789-.945 3.74 3.74 0 0 0-1.182 2.737v.886h.788v-.886a2.973 2.973 0 1 1 5.927 0v.886h.787v-.886a3.742 3.742 0 0 0-1.181-2.737 2.974 2.974 0 0 1 5.77.945v.886h.787v-.886zm-16.186-5.415a1.674 1.674 0 1 1 3.348 0 1.674 1.674 0 0 1-3.348 0zm5.376 1.792a1.674 1.674 0 1 1 3.348-.039 1.674 1.674 0 0 1-3.348.04zm5.375-1.792a1.674 1.674 0 1 1 3.348 0 1.674 1.674 0 0 1-3.347 0z"
      stroke="currentColor"
      strokeWidth=".7"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
)

export default IconCircleTeam
