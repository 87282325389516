export enum Layout {
  Main,
  Empty,
  CRM
}

export enum Skeleton {
  Default,
  CreateForm
}
