import { ComponentPropsWithoutRef } from 'react'

const IconComment = ({
  viewBox = '0 0 24 24',
  width = 24,
  height = 24,
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 4.25H21.75V21.9405L15.7636 17.75H2.25V4.25ZM3.75 5.75V16.25H16.2364L20.25 19.0595V5.75H3.75Z"
      fill="currentColor"
    />
  </svg>
)

export default IconComment
