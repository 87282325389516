import qs from 'qs'

import { removeEmptyProperties } from './object.util'

export const stringifyURLObject = (obj: object) => {
  const queryString = qs.stringify(removeEmptyProperties(obj))
  return queryString
}

export const parseURLObject = <T extends Record<string, unknown>>(
  query: string | string[] | undefined
) => {
  if (!query || Array.isArray(query)) {
    return null
  }

  const parsedObj = qs.parse(query) as T
  return parsedObj
}
