const IconAvatar = (props: { className?: string }) => (
  <svg viewBox="0 0 160px 160px" {...props}>
    <path
      d="M123.6,74.7c-3.1-9.5-19.5-31.8-22.7-36.1c1.6-2.2,2.7-4.8,3.1-7.8l0-0.2l-3-10.6l-1.1,0.6
c-0.1,0.1-10.1,5.8-15.2,8.2c-0.5,0.2-0.7,0.8-0.5,1.3c0.2,0.5,0.8,0.7,1.3,0.5c4.2-2,11.5-6.1,14.2-7.7l0.6,2.1
c-2,1.8-6.5,5.9-11.8,10.1c-7,5.5-20,1.6-32.4,5.3c-5.1,1.5-12,7.7-16.6,12.2c4-10.8,15.7-17.1,25.9-17.1c3.6,0,7.2-2.2,11-4.5
c2.3-1.4,4.7-2.9,7.3-4c0.5-0.2,0.7-0.8,0.5-1.3s-0.8-0.7-1.3-0.5c-2.7,1.2-5.2,2.7-7.5,4.1c-3.7,2.3-6.9,4.2-9.9,4.2
c-12,0-26,8.2-28.8,22c-1.2,1.3-1.9,2.1-1.9,2.1c-0.3,2-0.4,3.9-0.4,5.8c-0.2,0.6-0.3,1.3-0.3,2c0,5.4,2.2,4.8,1.1,8.5
C34.3,77.6,36.7,84.6,40,87c1.8,1.3,5.7,4.5,9,7.3c6,6.6,12.3,11.4,15.6,13.7c-0.1,5.8-1,18.6-6.7,27.5c-0.3,0.5-0.2,1.1,0.3,1.4
c0.2,0.1,0.3,0.2,0.5,0.2c0.3,0,0.6-0.2,0.8-0.5c5.6-8.8,6.8-20.8,7-27.2c0.1,0.1,0.2,0.2,0.2,0.2s4.6-1,4.6-15.5
c0-2.4-3.9-1.3-4.4-6.7c-0.1-1.2,0-2.2,0.3-3c3.1-2.1,5.4-2.2,5.4-2.2h0.9c0.1,0,0.1,0,0.1,0l0,0h0.3l-0.4-1.3
c-0.1-0.5-0.3-1-0.4-1.6L71,67.6c0,0-1.4-9.1,9.5-15.4c1-0.5,2-1,3-1.5c0.2-0.1,0.4-0.2,0.6-0.3c0.7-0.3,1.5-0.6,2.3-0.9
c6.9-2.4,10.9-5.8,13.2-9.4c0,0,0,0,0,0c1.7,2.3,5.7,7.8,9.9,14c-5.8-1.4-9.2,3-9.2,3.1c-0.3,0.4-0.2,1,0.2,1.4
c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4c0.1-0.2,3.2-4.1,8.6-1.9c0.2,0.1,0.4,0.1,0.6,0c4.6,7,9.1,14.4,10.6,18.7
c2.7,8.3,3,27.5-1.8,34.6c-2.1,3.1-17,10.6-38.1-2c-0.5-0.3-1.1-0.1-1.3,0.3c-0.3,0.5-0.1,1.1,0.3,1.3c9.3,5.5,17.4,7.4,23.9,7.4
c0.7,0,1.4,0,2.1-0.1c0,0.1-0.1,0.2-0.1,0.3c0,14.9,0.1,21.7,0.1,21.8c0,0.5,0.4,1,1,1c0,0,0,0,0,0c0.5,0,1-0.5,1-1
c0-0.1-0.1-6.8-0.1-21.7c0-0.2-0.1-0.3-0.1-0.5c7.1-0.9,11.7-3.9,13-5.7C126.8,103.3,126.5,83.5,123.6,74.7z"
    />
    <path
      d="M80.9,73.8c0.5,0,1-0.5,1-1c-0.1-7.8,10.4-9.9,10.5-9.9c0.5-0.1,0.9-0.6,0.8-1.1
c-0.1-0.5-0.6-0.9-1.1-0.8c-0.1,0-12.3,2.4-12.1,11.9C79.9,73.4,80.4,73.8,80.9,73.8C80.9,73.8,80.9,73.8,80.9,73.8z"
    />
    <path
      d="M115.3,92.1c-0.3-0.4-1-0.5-1.4-0.1c-0.1,0.1-6.9,5.5-12.6,4.9c-0.4,0-0.7,0.1-0.9,0.4
c-0.2,0.3-0.2,0.7-0.1,1c0.1,0.2,2.1,3.8,7.6,3.8c2.3,0,5.3-0.6,9-2.4c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.5-0.8-0.7-1.3-0.5
c-7.1,3.4-10.9,2.2-12.7,0.9c5.8-0.6,11.4-5.1,11.7-5.3C115.6,93.1,115.7,92.5,115.3,92.1z"
    />
    <path
      d="M98.2,71.4c-0.3-0.6-5.2-2.5-8,1.4c-1.5,2-2.5,5.2-2.5,5.2c0.3,1.7,2.3-2.1,3.9-3.9
c0.1,0.8,0.4,2.6,1.7,3.1c1.1,0.4,3-0.1,3.6-1.2c0.7-1.4-0.1-3.2-1.2-3.7C97.1,71.9,98.3,71.7,98.2,71.4z"
    />
    <path
      d="M106.3,64.7c-1.1,1.4-2,3.2-2.5,4.2c-2.2-3.1-3.7-5.7-3.8-5.7c-0.3-0.5-0.9-0.6-1.3-0.3
c-0.5,0.3-0.6,0.9-0.3,1.3c0.3,0.5,8.2,13.5,13.5,16.6c0.2,0.1,0.2,0.2,0.2,0.3c-0.1,1-1.9,3.1-3.8,3.9c-1.1,0.5-1.6,0.7-1.8,0.8
c-0.1,0-0.3,0.1-0.4,0.1c-0.4,0.3-0.6,0.9-0.4,1.3c0.2,0.4,0.4,0.6,0.7,0.6c0.5,0,1.2-0.3,2.7-1c2.3-1,4.9-3.6,5-5.6
c0-0.9-0.4-1.7-1.1-2.1c-2.5-1.5-5.8-5.6-8.5-9.3c0.9-0.9,2.4-3.1,3.6-4c0,0.2,0,2.8,1.7,3.5c1.1,0.4,3-0.1,3.6-1.2
c0.6-1.3,0-3-1.1-3.6c1.3-0.2,2.2-0.4,2.1-0.7C114,63.2,109.3,60.9,106.3,64.7z"
    />
    <path
      d="M90.6,93.8c-0.5-0.3-1.1-0.1-1.3,0.4l-1.4,2.5c-0.1,0.2-0.2,0.4-0.1,0.7l0.4,2.2
c0.1,0.5,0.5,0.8,1,0.8c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.6,0.8-1.2l-0.4-1.8l1.2-2.1C91.3,94.6,91.1,94,90.6,93.8z"
    />
  </svg>
)

export default IconAvatar
