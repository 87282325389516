export enum AdvertStatus {
  NonReviewed,
  Reviewed,
  Rejected
}

export enum AdvertVisibilityType {
  Active,
  Draft = 5,
  PausedBySubscription = 10,
  Paused = 20,
  Finished = 30
}

export enum AdvertTranslation {
  English,
  Lithuanian,
  EnglishLithuanian
}

export enum CreationStep {
  Information,
  Skills,
  Education,
  Industries,
  Languages,
  Responsibilities,
  Description,
  Proposal,
  Preview
}
