import { PropsWithChildren } from 'react'

import { FirebaseAuthContextProvider } from 'context/firebaseAuth'

import useFirebaseAuth from './useFirebaseAuth'

const FirebaseAuth = ({ children }: PropsWithChildren) => {
  const { status, signIn, signOut, user } = useFirebaseAuth()

  return (
    <FirebaseAuthContextProvider value={{ status, user, signIn, signOut }}>
      {children}
    </FirebaseAuthContextProvider>
  )
}
export default FirebaseAuth
