import clsx from 'clsx'

import Button from '../Button'

import { IButtonWithIcon } from './types'

const ButtonWithIcon = ({
  icon: Icon,
  className,
  children,
  swapped,
  isCenter,
  ...rest
}: IButtonWithIcon) => {
  return (
    <Button className={clsx('animated-svg', className)} {...rest}>
      <div
        className={clsx(
          'flex flex-row items-center gap-2',
          swapped && 'flex-row-reverse',
          isCenter && 'w-full justify-center'
        )}
      >
        <span>{children}</span>
        <Icon />
      </div>
    </Button>
  )
}
export default ButtonWithIcon
