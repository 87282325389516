const IconCircleDocument = (props: { className?: string }) => (
  <svg viewBox="0 0 34 34" {...props}>
    <path
      d="M17 0c9.389 0 17 7.611 17 17s-7.611 17-17 17S0 26.389 0 17 7.611 0 17 0zm0 2C8.716 2 2 8.716 2 17c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"
      fill="currentColor"
      fillRule="nonzero"
    />
    <g transform="translate(6, 6)">
      <path d="M15,10H7v2h8V10z" fill="currentColor" />
      <path d="M7,6h6v2H7V6z" fill="currentColor" />
      <path d="M15,14H7v2h8V14z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4,1H3v20h16V5.6L14.4,1z M5,19V3h8.6L17,6.4V19H5z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default IconCircleDocument
