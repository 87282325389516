const IconEdit = (props: { className?: string }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4493 1.89346L22.1061 7.55032L9.0853 20.5711L3.42845 20.5711L3.42845 14.9143L16.4493 1.89346ZM16.4493 4.72189L15.0262 6.14495L17.8546 8.97337L19.2777 7.55032L16.4493 4.72189ZM16.4404 10.3876L13.612 7.55916L5.42845 15.7427L5.42845 18.5711L8.25688 18.5711L16.4404 10.3876Z"
      fill="currentColor"
    />
  </svg>
)

export default IconEdit
