import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { IOrganisation } from 'data-transfers/dto'
import { IGetOrganisation } from 'data-transfers/requests'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { organisationsKeys } from './keys'
import { getOrganisation } from './organisation.service'

const useOrganisation = ({
  payload,
  options
}: IUseQueryHookWithPayload<IGetOrganisation, IOrganisation, AxiosError>) => {
  return useQuery(
    organisationsKeys.organisation(payload),
    async () => {
      return await getOrganisation(payload)
    },
    {
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes

      ...options
    }
  )
}
export default useOrganisation
