export enum MessageType {
  General,
  System
}

export enum SystemMessage {
  NewMatch,
  CandidateApplied,
  CandidateAutoApplied
}

export enum RepresentationFor {
  Candidate = 'candidate',
  Employer = 'employer'
}
