import { useQuery } from '@tanstack/react-query'
import { IUserMatchesRequest } from 'data-transfers/requests'
import { IMessagesContactsResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { messageKeys } from './keys'
import { getMatchesContacts } from './message.service'

const useUserMatchContacts = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IUserMatchesRequest,
  IMessagesContactsResponse
>) => {
  return useQuery(
    messageKeys.matches(payload),
    async () => {
      const data = await getMatchesContacts(payload)
      return data
    },
    options
  )
}

export default useUserMatchContacts
