import { useQuery } from '@tanstack/react-query'
import { IMessageTokenResponse } from 'data-transfers/responses'

import { IUseQueryHook } from 'hooks/types'

import { messageKeys } from './keys'
import { firebaseToken } from './message.service'

const useFirebaseToken = ({
  options
}: IUseQueryHook<undefined, IMessageTokenResponse>) => {
  return useQuery<IMessageTokenResponse>(
    messageKeys.firebaseToken(),
    async () => {
      return await firebaseToken()
    },
    {
      ...options
    }
  )
}

export default useFirebaseToken
