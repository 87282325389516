const IconInstagram = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M14.196 11.915a2.28 2.28 0 1 1-4.562 0 2.28 2.28 0 0 1 4.562 0z" />
      <path d="M17.249 7.88a2.12 2.12 0 0 0-.512-.787 2.118 2.118 0 0 0-.787-.512c-.241-.093-.603-.205-1.27-.235-.722-.033-.938-.04-2.765-.04-1.827 0-2.043.007-2.765.04-.667.03-1.03.142-1.27.235-.297.11-.566.285-.787.512-.227.221-.402.49-.512.787-.093.241-.205.603-.235 1.27-.033.722-.04.938-.04 2.765 0 1.827.007 2.043.04 2.765.03.667.142 1.03.235 1.27.11.297.285.566.512.787.22.227.49.402.787.512.24.094.603.205 1.27.235.722.033.938.04 2.765.04 1.827 0 2.043-.007 2.765-.04.667-.03 1.029-.141 1.27-.235a2.266 2.266 0 0 0 1.299-1.299c.093-.24.205-.603.235-1.27.033-.722.04-.938.04-2.765 0-1.827-.007-2.043-.04-2.765-.03-.667-.142-1.029-.235-1.27zm-5.334 7.548a3.513 3.513 0 1 1 0-7.026 3.513 3.513 0 0 1 0 7.026zm3.652-6.344a.821.821 0 1 1 0-1.642.821.821 0 0 1 0 1.642z" />
      <path d="M11.915 0C5.335 0 0 5.336 0 11.915c0 6.58 5.336 11.915 11.915 11.915 6.58 0 11.915-5.336 11.915-11.915C23.83 5.335 18.494 0 11.915 0zm6.8 14.736c-.033.728-.148 1.225-.317 1.66a3.498 3.498 0 0 1-2.001 2.002c-.435.169-.933.284-1.661.318-.73.033-.963.04-2.82.04-1.86 0-2.092-.007-2.822-.04-.728-.034-1.225-.15-1.66-.318a3.352 3.352 0 0 1-1.212-.79 3.353 3.353 0 0 1-.79-1.211c-.168-.435-.284-.933-.317-1.661-.034-.73-.042-.963-.042-2.821s.008-2.091.041-2.82c.034-.73.15-1.226.318-1.662.172-.456.442-.87.79-1.211a3.351 3.351 0 0 1 1.211-.79c.436-.169.933-.284 1.661-.318.73-.033.963-.04 2.821-.04s2.091.007 2.82.04c.73.034 1.226.15 1.662.318.456.172.87.442 1.212.79.347.341.617.755.789 1.211.169.436.284.933.318 1.661.033.73.04.963.04 2.821s-.007 2.091-.04 2.82z" />
    </g>
  </svg>
)

export default IconInstagram
