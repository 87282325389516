import { UserRole } from 'constants/auth'

const roleGates = (roles: UserRole[], role?: UserRole) => {
  if (!role) {
    return false
  }

  return roles.includes(role)
}

export const isOrgRole = (role?: UserRole) => {
  const roles = [UserRole.Organisation, UserRole.OrganisationEmployee]

  return roleGates(roles, role)
}

export const isCandidateRole = (role?: UserRole) => {
  const roles = [UserRole.Candidate]

  return roleGates(roles, role)
}
