import { useTranslation } from 'next-i18next'
import { MutationFunction, useMutation } from '@tanstack/react-query'
import { IOrganisation } from 'data-transfers/dto'
import { OrganisationOnboardingStatus } from 'enums'

import useOrgNewStore from 'store/useOrgNewStore'
import { IUseMutation } from 'hooks/types'

import { handleError } from 'utils/queryErrorHandler'

import {
  patchOrganisationAddress,
  patchOrganisationDescription,
  patchOrganisationGallery,
  patchOrganisationLanguages,
  patchOrganisationLogo,
  patchOrganisationMakePayment,
  patchOrganisationOverallInformation,
  patchOrganisationPhoto,
  patchOrganisationPolicy,
  patchOrganisationShowInformation,
  patchOrganisationSocialNetworks,
  patchOrganisationSubscription
} from './organisation.service'

export type OrganisationCreationSteps = Exclude<
  OrganisationOnboardingStatus,
  | OrganisationOnboardingStatus.PaymentPending
  | OrganisationOnboardingStatus.NewPending
  | OrganisationOnboardingStatus.Approved
  | OrganisationOnboardingStatus.UpdatePending
  | OrganisationOnboardingStatus.Rejected
>

const stepSavers = {
  [OrganisationOnboardingStatus.OverallInformation]:
    patchOrganisationOverallInformation,
  [OrganisationOnboardingStatus.Address]: patchOrganisationAddress,
  [OrganisationOnboardingStatus.ShowInformation]:
    patchOrganisationShowInformation,
  [OrganisationOnboardingStatus.Languages]: patchOrganisationLanguages,
  [OrganisationOnboardingStatus.Description]: patchOrganisationDescription,
  [OrganisationOnboardingStatus.SocialNetworks]:
    patchOrganisationSocialNetworks,
  [OrganisationOnboardingStatus.Logo]: patchOrganisationLogo,
  [OrganisationOnboardingStatus.Photo]: patchOrganisationPhoto,
  [OrganisationOnboardingStatus.Gallery]: patchOrganisationGallery,
  [OrganisationOnboardingStatus.PrivacyPolicy]: patchOrganisationPolicy,
  [OrganisationOnboardingStatus.Subscription]: patchOrganisationSubscription,
  [OrganisationOnboardingStatus.Payment]: patchOrganisationMakePayment
}

const useSaveOrganisationStep = <T extends OrganisationCreationSteps>(
  step: T,
  options: IUseMutation<
    Awaited<ReturnType<(typeof stepSavers)[T]>>,
    unknown,
    Parameters<(typeof stepSavers)[T]>[0]
  > = {}
) => {
  const setTempOrg = useOrgNewStore((state) => state.setTempOrg)
  const { t } = useTranslation(['actions'])
  const { onSuccess, ...rest } = options

  const saverFn = stepSavers[step] as MutationFunction<
    Awaited<ReturnType<(typeof stepSavers)[T]>>,
    Parameters<(typeof stepSavers)[T]>[0]
  >

  return useMutation(saverFn, {
    onError: (error) => {
      handleError({
        error,
        extraMessage: t('issues', { ns: 'actions' }),
        defaultMessage: t('toast.organisationErrorSaved', { ns: 'actions' })
      })
    },
    onSuccess(data, variables, context) {
      setTempOrg(data as unknown as IOrganisation)

      onSuccess?.(data, variables, context)
    },
    ...rest
  })
}

export default useSaveOrganisationStep
