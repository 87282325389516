const IconForward = ({
  big = false,
  ...props
}: {
  className?: string
  big?: boolean
  color?: string
}) => {
  if (big) {
    return (
      <svg
        viewBox="0 0 30 16"
        style={{ width: '22px', height: '22px' }}
        {...props}
      >
        <path
          fill={props.color || 'currentColor'}
          d="m23.077.929 6.367 6.364a1 1 0 0 1 0 1.414l-6.367 6.364a1 1 0 0 1-1.415-1.414L26.321 9H.269V7h26.052l-4.659-4.657A1 1 0 1 1 23.077.93z"
        />
      </svg>
    )
  }
  return (
    <svg
      viewBox="0 0 22 22"
      style={{ width: '22px', height: '22px' }}
      {...props}
    >
      <path
        fill={props.color || 'currentColor'}
        d="m17.524 10-2.937-2.937L16 5.65l5.352 5.352-1.415 1.414-.006-.006-3.944 3.944-1.414-1.415 2.938-2.937H1.35v-2z"
      />
    </svg>
  )
}

export default IconForward
