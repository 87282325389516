import { useMutation, useQueryClient } from '@tanstack/react-query'
import { INotificationDto } from 'data-transfers/dto/notification'

import { IUseMutation } from 'hooks/types'

import { userProfileKeys } from './keys'
import { notificationWasShown } from './profile.service'

const useNotificationWasShown = ({
  onSuccess,
  ...options
}: IUseMutation<INotificationDto, unknown, INotificationDto> = {}) => {
  const queryClient = useQueryClient()
  return useMutation(notificationWasShown, {
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(userProfileKeys.notifications(), data)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useNotificationWasShown
