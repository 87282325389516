const IconMicrosoft = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M11.7,19H5v-6.7h6.7V19z M11.7,11.7H5V5h6.7
	V11.7z M19,19h-6.7v-6.7H19V19z M19,11.7h-6.7V5H19V11.7z"
      fill="currentColor"
    />
  </svg>
)

export default IconMicrosoft
