import { ComponentPropsWithoutRef } from 'react'

const IconSortDesc = ({
  strokeWidth = 1.5,
  stroke = 'currentColor',
  viewBox = '0 0 24 24',
  className = 'fade-in ml-2 inline-block h-4 w-4',
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    strokeWidth={strokeWidth}
    stroke={stroke}
    className={className}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"
    />
  </svg>
)

export default IconSortDesc
