import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IUserSettings } from 'data-transfers/dto'

import { IUseMutation } from 'hooks/types'

import { userProfileKeys } from './keys'
import { saveUserSettings } from './profile.service'

const useSaveUserSettings = ({
  onSuccess,
  ...options
}: IUseMutation<IUserSettings, unknown, IUserSettings>) => {
  const queryClient = useQueryClient()

  return useMutation(saveUserSettings, {
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData(userProfileKeys.settings(), data)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useSaveUserSettings
