import {
  Tooltip,
  TooltipContent,
  TooltipProps,
  TooltipTrigger
} from './components'

const TextTooltip = ({
  children,
  tooltipContentClassName,
  tooltipContent,
  className,
  disabled,
  placement
}: TooltipProps) => {
  return (
    <Tooltip disabled={disabled} placement={placement}>
      <TooltipTrigger className={className}>{children}</TooltipTrigger>
      <TooltipContent className={tooltipContentClassName}>
        {tooltipContent}
      </TooltipContent>
    </Tooltip>
  )
}

export default TextTooltip
