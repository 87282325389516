export enum TrackingType {
  Candidate = 'candidate',
  Advert = 'advert'
}

export enum TimePeriods {
  Daily,
  Weekly,
  Monthly
}
