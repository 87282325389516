import * as React from 'react'

import { ContextType } from './types'

export const TooltipContext = React.createContext<ContextType>(null)

const useTooltipContext = () => {
  const context = React.useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export default useTooltipContext
