import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import Router from 'next/router'
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { IError } from 'data-transfers/dto/error'

import { getJwtInfo } from 'utils/cookies'
import { paramsSerializer } from 'utils/serializer'
import { isSSR } from 'utils/ssr.util'
import { SubscriptionStatus } from 'constants/subscription'

import envUtil from '../env'

export const { CancelToken } = axios

const { apiUrl } = envUtil.getEnv()
const baseConfig: AxiosRequestConfig = {
  baseURL: `${apiUrl.replace(/\/$/, '')}/api/v1`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  paramsSerializer
}

export const publicClient = axios.create(baseConfig)
export const authClient = axios.create(baseConfig)

publicClient.interceptors.request.use((request) => {
  request.headers = request.headers && setAcceptLanguageHeader(request.headers)
  return request
})

authClient.interceptors.request.use((request) => {
  const { jwt } = getJwtInfo()

  if (request.headers) {
    if (jwt) {
      request.headers.Authorization = 'Bearer ' + jwt
    }
    request.headers = setAcceptLanguageHeader(request.headers)
  }
  return request
})

export const setSSRJwt = (coockies: NextApiRequestCookies) => {
  if (!coockies) {
    return
  }
  const { jwt } = getJwtInfo(coockies)

  setJwtToken(jwt)
}
authClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error) && error.response?.status === 403) {
      const data = error.response?.data as IError
      if (
        data.errors?.['subscription'] ===
        SubscriptionStatus[SubscriptionStatus.Expired]
      ) {
        Router.push('/subscription')
      }
    }
    return Promise.reject(error)
  }
)

export const setAxiosJwt = (jwt: string | undefined) => {
  if (!jwt || jwt.length < 8) {
    throw Error('The wrong JWT token')
  }
  setJwtToken(jwt)
}

const setJwtToken = (jwt?: string | null) => {
  if (!jwt) {
    return
  }
  authClient.defaults.headers.common.Authorization = 'Bearer ' + jwt
}

const setAcceptLanguageHeader = (headers: AxiosRequestHeaders) => {
  if (isSSR() || !Router.locale) {
    return headers
  }
  return { ...headers, ['Accept-Language']: Router.locale }
}
