import { serverTimestamp as realtimeTimestamp } from 'firebase/database'
import { serverTimestamp } from 'firebase/firestore'

export type UserPresence = 'offline' | 'online'

export const isOfflineForDatabase = {
  state: 'offline',
  lastChanged: realtimeTimestamp()
}
export const isOnlineForDatabase = {
  state: 'online',
  lastChanged: realtimeTimestamp()
}
export const isOfflineForFirestore = {
  state: 'offline',
  lastChanged: serverTimestamp()
}

export const isOnlineForFirestore = {
  state: 'online',
  lastChanged: serverTimestamp()
}
