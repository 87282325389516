import { IOrganisation } from 'data-transfers/dto'
import create from 'zustand'
import { persist } from 'zustand/middleware'

import { IPaymentType } from 'components/Fields/PaymentTypeRadio'

import { OrganisationOnboardingStatus } from '../enums'

export interface OrgNewStore {
  majorStep: number
  passedSteps: number[]
  activeStep: number
  tempOrg: Partial<IOrganisation> | null
  isEditing: boolean
  countryCode: string
  paymentType: IPaymentType

  setIsEditing: (isEditing: boolean) => void
  setTempOrg: (org: Partial<IOrganisation>) => void
  setMajorStep: (majorStep: number) => void
  setStep: (step: number) => void
  setCountryCode: (code: string) => void
  setPaymentType: (type: IPaymentType) => void
  incStep: (step: OrganisationOnboardingStatus) => void
  decStep: (step: OrganisationOnboardingStatus, isMajor?: boolean) => void
  clearOrg: () => void
}

const useOrgNewStore = create<OrgNewStore>()(
  persist(
    (set) => ({
      paymentType: 'ProForma',
      countryCode: 'en',
      majorStep: 0,
      passedSteps: [0],
      activeStep: 0,
      tempOrg: null,
      isEditing: false,
      setIsEditing: (isEditing: boolean) => set(() => ({ isEditing })),
      setMajorStep: (majorStep: number) => set(() => ({ majorStep })),
      setTempOrg: (tempOrg) =>
        set((state) => ({
          ...state,
          countryCode: tempOrg.countryCode ?? state.countryCode,
          tempOrg: {
            ...state.tempOrg,
            ...tempOrg
          }
        })),
      setStep: (step: number) =>
        set((state) => {
          const passedSteps = Array.from(
            new Set([
              ...state.passedSteps,
              ...Array.from(Array(step).keys()),
              step
            ])
          )
          return { ...state, activeStep: step, passedSteps }
        }),
      setCountryCode: (code: string) =>
        set((state) => {
          return { ...state, countryCode: code }
        }),
      setPaymentType: (type: IPaymentType) =>
        set((state) => {
          return { ...state, paymentType: type }
        }),
      incStep: (step: OrganisationOnboardingStatus) =>
        set((state) => {
          if (!state.passedSteps.includes(step)) {
            return {
              ...state,
              activeStep: step,
              passedSteps: [...state.passedSteps, step]
            }
          } else {
            return { ...state, activeStep: step }
          }
        }),
      decStep: (step: OrganisationOnboardingStatus, isMajor) =>
        set((state) => ({
          ...state,
          activeStep: step,
          majorStep: Math.max(
            isMajor ? state.majorStep - 1 : state.majorStep,
            0
          )
        })),
      clearOrg: () =>
        set((state) => ({
          ...state,
          isEditing: false,
          majorStep: 0,
          passedSteps: [0],
          activeStep: 0,
          countryCode: 'en',
          tempOrg: null
        }))
    }),
    {
      name: 'hrizer-org-new',
      getStorage: () => {
        return localStorage
      }
    }
  )
)

export default useOrgNewStore
