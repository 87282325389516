import { ComponentPropsWithoutRef } from 'react'

const IconSortAsc = ({
  strokeWidth = 1.5,
  className = 'fade-in ml-2 inline-block h-4 w-4',
  stroke = 'currentColor',
  viewBox = '0 0 24 24',
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    strokeWidth={strokeWidth}
    stroke={stroke}
    className={className}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
    />
  </svg>
)

export default IconSortAsc
