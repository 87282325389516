import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import axios from 'axios'
import { IGetOrganisation } from 'data-transfers/requests'
import { OnBoardingStatus, OrganisationOnboardingStatus } from 'enums'

import { getRestrictions } from 'hooks/useAuth/auth.service'
import { getOrganisation } from 'hooks/useOrganisation'

import { Description } from 'validators/descriptionValidator'
import { ILocale } from 'types/app'

import { getSession } from './auth'
import { setSSRJwt } from './client'
import { isCandidateRole } from './role.util'
export const getSSROrganisatation = async (
  coockies: NextApiRequestCookies,
  params: Omit<IGetOrganisation, 'id'>
) => {
  const session = getSession(coockies)
  setSSRJwt(coockies)

  const id = session?.user?.organisationId
  if (!id) {
    return null
  }

  try {
    return await getOrganisation({ id, ...params })
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null
    }
    throw error
  }
}

export interface ICheckOrganisationAccess {
  coockies?: NextApiRequestCookies
  data?: {
    onboardingStatus: OnBoardingStatus | OrganisationOnboardingStatus
  } | null
  locale: string
  disableReviewCheck?: boolean
}
const redirect = (destination: string) => ({
  redirect: {
    permanent: false,
    destination
  }
})
export const checkAccess = async ({
  data,
  coockies,
  disableReviewCheck,
  locale
}: ICheckOrganisationAccess) => {
  const session = getSession(coockies ?? {})
  if (!data) {
    setSSRJwt(coockies ?? {})
    const restrictions = await getRestrictions()

    const onboardingStatus =
      restrictions?.candidate?.onboardingStatus ||
      restrictions?.organisation?.onboardingStatus

    data =
      onboardingStatus != null
        ? {
            onboardingStatus: onboardingStatus
          }
        : null
  }

  if (!data || !session) {
    return null
  }

  if (isCandidateRole(session.user.role)) {
    const candidateOnboardingStatus = data.onboardingStatus as OnBoardingStatus
    if (isCandidateInRegistrationProcess(candidateOnboardingStatus)) {
      return redirect(`/${locale}/user/new`)
    }
    return null
  }

  const orgOnboardingStatus =
    data.onboardingStatus as OrganisationOnboardingStatus

  if (isOrgInRegistrationProcess(orgOnboardingStatus)) {
    return redirect(`/${locale}/org/new`)
  }

  if (
    orgOnboardingStatus != OrganisationOnboardingStatus.Approved &&
    !disableReviewCheck
  ) {
    return redirect(`/${locale}/org/company-profile`)
  }
}
export const isCandidateInRegistrationProcess = (
  onboardingStatus: OnBoardingStatus | undefined
) => {
  if (onboardingStatus === undefined) {
    return false
  }
  return ![OnBoardingStatus.Recheck, OnBoardingStatus.Completed].includes(
    onboardingStatus
  )
}

export const isOrgHasSubscriptionAccess = (
  onboardingStatus: OrganisationOnboardingStatus | undefined
) => {
  return !(
    onboardingStatus &&
    onboardingStatus >= OrganisationOnboardingStatus.NewPending
  )
}

export const isOrgInRegistrationProcess = (
  onboardingStatus: OrganisationOnboardingStatus
) => {
  return onboardingStatus < OrganisationOnboardingStatus.NewPending
}

export const getOrganisationDescriptions = (
  countryCode?: string | null,
  descriptions?: Description[]
) => {
  const langKeys: ILocale[] = ['en']
  let [defaultLocationCode] = langKeys
  if (countryCode === 'LT') {
    langKeys.push('lt')
    defaultLocationCode = 'lt'
  }

  return langKeys.map((lang) => {
    const currentDesc = descriptions?.find((item) => item.languageCode === lang)
    const isDefaultValue = defaultLocationCode === lang

    if (currentDesc) {
      return {
        ...currentDesc,
        isDefaultValue
      }
    }

    return {
      value: '',
      languageCode: lang,
      isDefaultValue
    }
  })
}
