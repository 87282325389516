import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useMemo } from 'react'

import { IModalType } from 'components/modals/ModalAuth/types'

import { usePageAuthSettings } from 'context'

import { AuthAccessLevel } from 'constants/auth'

import { IUseSkeletonProps } from './types'

const useSkeleton = ({ showsForRoles }: IUseSkeletonProps) => {
  const router = useRouter()
  const defaultModalTypeQuery = router.query.modalType as string | undefined
  const { permissions, accessLevel } = usePageAuthSettings()
  const { status, data } = useSession()
  const type = useMemo(() => permissions?.[0], [permissions])
  const showAuthModal = useMemo(
    () =>
      (status === 'unauthenticated' &&
        accessLevel == AuthAccessLevel.Authorized) ||
      (data?.user && showsForRoles?.includes(data.user.role)),
    [accessLevel, data?.user, showsForRoles, status]
  )
  const defaultModalType = useMemo<IModalType | undefined>(() => {
    if (
      defaultModalTypeQuery === 'SignUp' ||
      defaultModalTypeQuery === 'SignIn'
    ) {
      return defaultModalTypeQuery
    }

    return
  }, [defaultModalTypeQuery])

  return {
    defaultModalType,
    type,
    showAuthModal
  }
}

export default useSkeleton
