import type { SessionContextValue } from 'next-auth/react'

import { UserRole } from 'constants/auth'

const getAuthRedirect = <R extends boolean = false>(
  session: SessionContextValue<R>
) => {
  if (session.status !== 'authenticated') {
    return '/sign-in'
  }

  // Handle additional redirects here
  switch (session.data.user?.role) {
    case UserRole.Candidate:
      return '/user'
    case UserRole.Organisation:
      return '/org'
    case UserRole.OrganisationEmployee:
      return '/org'
    case UserRole.Admin:
      return '/admin'
  }

  return '/'
}

export default getAuthRedirect
