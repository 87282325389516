import { ComponentPropsWithoutRef } from 'react'

const IconEmail = ({
  viewBox = '0 0 24 24',
  width = 24,
  height = 24,
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    {...props}
  >
    <path
      d="M2.25 4.25H21.75V19.75H2.25V4.25ZM3.75 5.75V7.5126L12 11.1793L20.25 7.5126V5.75H3.75ZM20.25 9.15407L12 12.8207L3.75 9.15407V18.25H20.25V9.15407Z"
      fill="currentColor"
    />
  </svg>
)

export default IconEmail
