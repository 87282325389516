const IconMessages = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2H19V7H22V21.9215L16.8165 18.2903H6.075V14.992L2 17.8464V2ZM6.075 12.5502V7H17V4H4V14.0036L6.075 12.5502ZM8.075 16.2903V9H20V18.0785L17.4474 16.2903H8.075Z"
      fill="currentColor"
    />
  </svg>
)

export default IconMessages
