const IconProject = (props: { className?: string }) => (
  <svg viewBox="0 0 22 22" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6,1.5h10v4h5v15H1v-15h5V1.5z M8,5.5h6v-2H8V5.5z M3,11.7v6.8h16v-6.8l-8,1.8
	L3,11.7z M19,9.7V7.5H3v2.2l8,1.8L19,9.7z"
      fill="currentColor"
    />
  </svg>
)

export default IconProject
