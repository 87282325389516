const IconMessages = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h22v22H0z" />
      <path
        d="M1.075 2H21v18.922l-5.184-3.632H1.075V2zm2 13.29V4H19v13.078l-2.553-1.788H3.075z"
        fill="currentColor"
      />
      <path
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
        d="M6 8h10M6 11h7"
      />
    </g>
  </svg>
)

export default IconMessages
