import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { Session } from 'next-auth'

import { cookiesUtils, jwtUtils } from 'utils'
export const getSession = (cookies: NextApiRequestCookies): Session | null => {
  const { jwt, expires, refreshToken } = cookiesUtils.getJwtInfo(cookies)
  if (!jwt || !refreshToken) {
    return null
  }

  const { user, jwtInfo } = jwtUtils.parseUser(jwt)

  return {
    user,
    refreshToken,
    jwt,
    jwtInfo,
    expires: expires || new Date(jwtInfo.exp).toISOString()
  }
}
