import { CSSProperties } from 'react'

const IconArrow = (props: { className?: string; style?: CSSProperties }) => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" {...props}>
    <path
      fill="currentColor"
      d="m17.524 10-2.937-2.937L16 5.65l5.352 5.352-1.415 1.414-.006-.006-3.944 3.944-1.414-1.415 2.938-2.937H1.35v-2z"
    />
  </svg>
)

export default IconArrow
