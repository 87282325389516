import { createContext, useContext } from 'react'

import { IAuthInitialProps } from 'types/app'
import { AuthAccessLevel } from 'constants/auth'

const defaultAuthSettings: IAuthInitialProps = {
  accessLevel: AuthAccessLevel.Public,
  permissions: []
}

export const PageAuthSettingsContext = createContext(defaultAuthSettings)

export const usePageAuthSettings = () => {
  return useContext(PageAuthSettingsContext)
}

export const PageAuthSettingsContextProvider = PageAuthSettingsContext.Provider
