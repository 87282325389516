import { IMetaProps } from 'components/MetaTags/types'

const keywords: IMetaProps = {
  property: 'keywords',
  content: 'Hrizer Job Hunt Job Search',
  key: 'keywords'
}

const siteName: IMetaProps = {
  property: 'og:site_name',
  content: 'HRizer',
  key: 'site_name'
}

const ogLocale: IMetaProps = {
  property: 'og:locale',
  content: 'en_US',
  key: 'locale'
}

const ogLocaleAlternate: IMetaProps = {
  property: 'og:locale:alternate',
  content: 'lt_LT',
  key: 'locale:alternate'
}

const metaTags = { keywords, siteName, ogLocale, ogLocaleAlternate }

export default metaTags
