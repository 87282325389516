import { createContext, useContext } from 'react'

import { ILocale } from 'types/app'

export interface ILanguageSettingsContext {
  switchLanguage: (locale: ILocale) => void
}

const defaultAuthSettings: ILanguageSettingsContext = {
  switchLanguage: () => {
    console.warn('The switchLanguage function is not provided')
  }
}

const LanguageSettingsContext = createContext(defaultAuthSettings)

export const useLanguageSettings = () => {
  return useContext(LanguageSettingsContext)
}

export const LanguageSettingsContextProvider = LanguageSettingsContext.Provider
