import { SVGProps } from 'react'

const IconPause = ({
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M11.9,1.1c6.1,0,11,4.9,11,11s-4.9,11-11,11s-11-4.9-11-11S5.8,1.1,11.9,1.1z M11.9,3.1c-5,0-9,4-9,9s4,9,9,9
		s9-4,9-9S16.9,3.1,11.9,3.1z M10.4,8.5v8h-2v-8H10.4z M15.4,8.5v8h-2v-8H15.4z"
    />
  </svg>
)

export default IconPause
