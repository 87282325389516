import { useCallback } from 'react'
import clsx from 'clsx'
import { useSwiper } from 'swiper/react'

import Spinner from 'components/Spinner'
import { IconArrow } from 'icons'

import { ISwiperButtonProps } from './types'

const SwiperButton = ({
  className,
  buttonClassName,
  type,
  isLoading,
  onClick
}: ISwiperButtonProps) => {
  const swiper = useSwiper()
  const switchSlide = useCallback(() => {
    if (type === 'next') {
      swiper.slideNext()
      return
    }
    swiper.slidePrev()
  }, [swiper, type])

  return (
    <div
      className={clsx(
        'absolute top-0 z-10 flex h-full items-center',
        type === 'next' && 'right-0 ',
        type === 'prev' && 'left-0',
        className
      )}
    >
      <button
        disabled={isLoading}
        className={clsx(
          'border-[2px] border-black p-2 text-black transition-colors hover:border-red-550 hover:text-red-550',
          buttonClassName,
          type === 'prev' && 'rotate-180'
        )}
        onClick={() => {
          switchSlide()
          onClick?.(swiper)
        }}
      >
        {isLoading ? <Spinner isVisible size={22} /> : <IconArrow />}
      </button>
    </div>
  )
}

export default SwiperButton
