const IconPDF = (props: { className?: string }) => (
  <svg viewBox="0 0 24 24" width="22px" height="22px" {...props}>
    <path d="M16 11H8V13H16V11Z" fill="currentColor" />
    <path d="M8 7H14V9H8V7Z" fill="currentColor" />
    <path d="M16 15H8V17H16V15Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4142 2H4V22H20V6.58579L15.4142 2ZM6 20V4H14.5858L18 7.41421V20H6Z"
      fill="currentColor"
    />
  </svg>
)

export default IconPDF
